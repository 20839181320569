import React, { useEffect, useState } from "react";
import { View, Text, ActivityIndicator, StyleSheet, ImageBackground, ScrollView, Button } from "react-native";
import axios from "axios";
import { Picker } from "@react-native-picker/picker";
import { BASE_URL } from "@env";
import styles from "./styles";

const SprayChartScreen = ({ route, navigation }) => {
  const { playerId, season, team } = route.params;
  const [playerInfo, setPlayerInfo] = useState(null);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [seasons, setSeasons] = useState([]);
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(season);
  const [selectedTeam, setSelectedTeam] = useState(team);
  const [selectedPlayer, setSelectedPlayer] = useState(playerId);
  const [selectedCategory, setSelectedCategory] = useState("total");
  const categories = ["total", "exhibition", "regular season", "playoff"];

  const [locationPercentage, setLocationPercentage] = useState({
    P: { hits: 0, outs: 0, total: 0 },
    C: { hits: 0, outs: 0, total: 0 },
    "1B": { hits: 0, outs: 0, total: 0 },
    "2B": { hits: 0, outs: 0, total: 0 },
    "3B": { hits: 0, outs: 0, total: 0 },
    SS: { hits: 0, outs: 0, total: 0 },
    LF: { hits: 0, outs: 0, total: 0 },
    CF: { hits: 0, outs: 0, total: 0 },
    RF: { hits: 0, outs: 0, total: 0 },
    LR: { hits: 0, outs: 0, total: 0 },
    RR: { hits: 0, outs: 0, total: 0 },
    NA: { hits: 0, outs: 0, total: 0 },
  });
  const locations = ["P", "C", "1B", "2B", "3B", "SS", "LF", "CF", "RF", "LR", "RR", "NA"];

  useEffect(() => {
    const fetchSeasons = async () => {
      try {
        const teamsResponse = await axios.get(`${BASE_URL}/api/team`);
        const uniqueSeasons = [...new Set(teamsResponse.data.map((team) => team.season))];
        setSeasons(uniqueSeasons);
      } catch (error) {
        console.error("Error fetching seasons:", error);
      }
    };

    fetchSeasons();
  }, []);

  useEffect(() => {
    if (selectedSeason) {
      const fetchTeams = async () => {
        try {
          const teamsResponse = await axios.get(`${BASE_URL}/api/team`);
          const filteredTeams = teamsResponse.data.filter(
            (team) => team.season === parseInt(selectedSeason)
          );
          setTeams(filteredTeams);
        } catch (error) {
          console.error("Error fetching teams:", error);
        }
      };

      fetchTeams();
    }
  }, [selectedSeason]);

  useEffect(() => {
    if (selectedTeam) {
      const fetchPlayers = async () => {
        try {
          const teamResponse = await axios.get(`${BASE_URL}/api/team/${selectedTeam}`);
          setPlayers(teamResponse.data.players);
        } catch (error) {
          console.error("Error fetching players:", error);
        }
      };

      fetchPlayers();
    }
  }, [selectedTeam]);

  useEffect(() => {
    const fetchPlayerData = async () => {
      try {
        const playerResponse = await axios.get(`${BASE_URL}/api/player/${selectedPlayer}`);
        setPlayerInfo(playerResponse.data);

        const statsResponse = await axios.get(`${BASE_URL}/api/player/${selectedPlayer}/stats`);
        setStats(statsResponse.data);
        calculateLocationStats(statsResponse.data[selectedCategory]);
      } catch (error) {
        console.error("Error fetching player data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayerData();
  }, [selectedPlayer, selectedCategory]);

  const calculateLocationStats = (statsData) => {
    const calculatedLocationStats = {};
    const calculatedLocationPercentage = {};
    let totalAtBats = 0;

    for (const location of locations) {
      calculatedLocationStats[location] = {
        hits: statsData.hitLocations[location] || 0,
        outs: statsData.outLocations[location] || 0,
        total: (statsData.hitLocations[location] || 0) + (statsData.outLocations[location] || 0),
      };
      totalAtBats += calculatedLocationStats[location].total;
    }

    for (const location of locations) {
      calculatedLocationPercentage[location] = {
        hits: ((calculatedLocationStats[location].hits / totalAtBats) * 100).toFixed(2),
        outs: ((calculatedLocationStats[location].outs / totalAtBats) * 100).toFixed(2),
        total: ((calculatedLocationStats[location].total / totalAtBats) * 100).toFixed(2),
      };
    }

    setLocationPercentage(calculatedLocationPercentage);
  };

  const getColor = (percentage) => {
    const value = Math.round((255 * (100 - percentage)) / 100);
    const hexValue = value.toString(16).padStart(2, '0');
    return hexValue;
  };

  if (loading) {
    return <ActivityIndicator size="large" color="#0000ff" />;
  }

  if (!stats) {
    return (
      <View style={styles.container}>
        <Text>No spray chart stats available for this player.</Text>
      </View>
    );
  }

  const renderLocationStats = (location, label) => {
    const { hits, outs, total } = locationPercentage[location];
    if (total === "0.00") return null; // Only render if total percentage is non-zero

    const colorHex = getColor(total);

    return (
      <View style={[overlayStyles.locationContainer, overlayStyles[location]]} key={location}>
        <Text style={[overlayStyles.label, { color: `#${colorHex}${colorHex}${colorHex}` }]}>{label}</Text>
        <Text style={[overlayStyles.stat, { color: `#00${colorHex}00` }]}>
          {stats[selectedCategory].hitLocations[location]}{" "}
          <Text style={{ color: `#${colorHex}0000` }}>{stats[selectedCategory].outLocations[location]}</Text>
        </Text>
      </View>
    );
  };

  const renderLegend = () => {
    return (
      <View style={overlayStyles.legendContainer}>
        <Text style={overlayStyles.legendLabel}>Legend:</Text>
        <Text style={overlayStyles.legendItem}>
          <Text style={[overlayStyles.legendText, { color: "#000000" }]}>Position</Text> /
          <Text style={[overlayStyles.legendText, { color: "#00FF00" }]}> Hits</Text> /
          <Text style={[overlayStyles.legendText, { color: "#FF0000" }]}> Outs</Text>
        </Text>
      </View>
    );
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.header}>
        {playerInfo && (
          <>
            <Text
              style={styles.h4}
            >{`#${playerInfo.number} - ${playerInfo.firstName} ${playerInfo.lastName}`}</Text>
          </>
        )}
      </View>
      <View style={styles.pickerContainer}>
        <Picker
          selectedValue={selectedSeason}
          onValueChange={(itemValue) => setSelectedSeason(itemValue)}
        >
          <Picker.Item label="Select Season" value="" />
          {seasons.map((season) => (
            <Picker.Item key={season} label={`${season}`} value={season} />
          ))}
        </Picker>
      </View>

      <View style={styles.pickerContainer}>
        <Picker
          selectedValue={selectedTeam}
          onValueChange={(itemValue) => setSelectedTeam(itemValue)}
          enabled={!!selectedSeason}
        >
          <Picker.Item label="Select Team" value="" />
          {teams.map((team) => (
            <Picker.Item key={team._id} label={team.name} value={team._id} />
          ))}
        </Picker>
      </View>

      <View style={styles.pickerContainer}>
        <Picker
          selectedValue={selectedPlayer}
          onValueChange={(itemValue) => setSelectedPlayer(itemValue)}
          enabled={!!selectedTeam}
        >
          <Picker.Item label="Select Player" value="" />
          {players.map((player) => (
            <Picker.Item key={player._id} label={`${player.firstName} ${player.lastName} (#${player.number})`} value={player._id} />
          ))}
        </Picker>
      </View>

      <View style={styles.pickerContainer}>
        <Picker
          selectedValue={selectedCategory}
          onValueChange={(itemValue) => setSelectedCategory(itemValue)}
        >
          {categories.map((category) => (
            <Picker.Item key={category} label={category} value={category} />
          ))}
        </Picker>
      </View>
        
      <View style={styles.buttonContainer}>
        <View style={styles.button}>
        <Button
          title="See Stats"
          onPress={() =>
            navigation.navigate("PlayerStats", {
              playerId: selectedPlayer,
              season: selectedSeason,
              team: selectedTeam,
            })
          }
        />
        </View>
      </View>

      <ImageBackground
        source={require("../../assets/field.png")}
        style={overlayStyles.imageBackground}
      >
        {locations.map((location) => renderLocationStats(location, location))}
        {renderLegend()}
      </ImageBackground>
    </ScrollView>
  );
};

const overlayStyles = StyleSheet.create({
  imageBackground: {
    width: "100%",
    height: 500,
    justifyContent: "center",
    alignItems: "center",
  },
  locationContainer: {
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    padding: 5,
    borderRadius: 5,
    alignItems: "center",
  },
  label: {
    fontWeight: "bold",
  },
  stat: {
    fontSize: 12,
  },
  legendContainer: {
    position: "absolute",
    bottom: 10,
    left: 10,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    padding: 5,
    borderRadius: 5,
  },
  legendLabel: {
    fontWeight: "bold",
    marginBottom: 2,
  },
  legendItem: {
    flexDirection: "row",
    alignItems: "center",
  },
  legendText: {
    fontSize: 12,
  },
  pickerContainer: {
    marginVertical: 10,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    overflow: "hidden",
    width: "90%",
    alignSelf: "center",
  },
  buttonContainer: {
    marginVertical: 10,
    alignItems: "center",
  },
  P: { top: "45%", left: "45%" },
  C: { top: "80%", left: "47.5%" },
  "1B": { top: "55%", left: "65%" },
  "2B": { top: "45%", left: "55%" },
  "3B": { top: "55%", left: "30%" },
  SS: { top: "45%", left: "40%" },
  LF: { top: "30%", left: "20%" },
  CF: { top: "20%", left: "47.5%" },
  RF: { top: "30%", left: "75%" },
  LR: { top: "35%", left: "30%" },
  RR: { top: "35%", left: "65%" },
  NA: { top: "80%", left: "10%" },
});

export default SprayChartScreen;
