import React from "react";
import { View, Text } from "react-native";
import styles from "./styles";

const GameInfoBox = ({ game, openModal }) => {
  const isTopInning = game.inningHalf === "top";
  const inningIndex = game.currentInning - 1;
  if (!game.awayInnings[inningIndex])
    {game.awayInnings[inningIndex] = { outs: 0, runs: 0, atBats: [] }};
  if (!game.homeInnings[inningIndex])
    {game.homeInnings[inningIndex] = { outs: 0, runs: 0, atBats: [] }};
  const inningOuts = isTopInning? game.awayInnings[inningIndex].outs : game.homeInnings[inningIndex].outs;
  const runsThisInning = isTopInning? game.awayInnings[inningIndex].runs : game.homeInnings[inningIndex].runs;
  return (
    <View style={styles.box}>
      <View style={styles.inningRow}>
        <Text>{game.inningHalf === "top" ? "Top" : "Bottom"} of </Text>
        <Text>Inning: {game.currentInning}</Text>
      </View>
      {/* <TouchableOpacity onPress={() => openModal("currentOuts", game.currentOuts)}> */}
        <Text>Outs: {inningOuts}</Text>
      {/* </TouchableOpacity> */}
      <Text>Runs this Inning: {runsThisInning}</Text>
    </View>
  );
};

export default GameInfoBox;
