import React from "react";
import {
  View,
  Modal,
  Text,
  TextInput,
  TouchableOpacity,
  Button,
} from "react-native";
import styles from "./styles";

const ScoreModal = ({
  isModalVisible,
  closeModal,
  modalType,
  modalValue,
  handleValueChange,
  incrementValue,
  decrementValue,
  saveChanges,
}) => {
  return (
    <Modal
      visible={isModalVisible}
      transparent={true}
      animationType="slide"
      onRequestClose={closeModal}
    >
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>{modalType}</Text>
          <View style={styles.inputContainer}>
            <TextInput
              style={styles.input}
              keyboardType="numeric"
              value={String(modalValue)}
              onChangeText={handleValueChange}
              placeholder="Enter value"
            />
            <View style={styles.incrementDecrementContainer}>
              <TouchableOpacity
                onPress={incrementValue}
                style={styles.incrementDecrementButton}
              >
                <Text style={styles.incrementDecrementText}>+</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={decrementValue}
                style={styles.incrementDecrementButton}
              >
                <Text style={styles.incrementDecrementText}>-</Text>
              </TouchableOpacity>
            </View>
          </View>
          <Button title="Save" onPress={saveChanges} color="green" />
          <Button title="Cancel" onPress={closeModal} color="red" />
        </View>
      </View>
    </Modal>
  );
};

export default ScoreModal;
