// CustomHeaderRight.js
import React, { useContext } from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import AuthContext from "../AuthContext";
import { Icon } from "@rneui/themed";
import styles from "./styles";

const CustomHeaderRight = ({ navigation }) => {
  const { user } = useContext(AuthContext);

  return (
    <View style={styles.headerContainer}>
      {/* <View style={styles.icon}> */}
        <View style={styles.headerText}>
        <TouchableOpacity onPress={() => navigation.navigate("Home")}>
        {/* <Icon name="home" type="font-awesome-5" /> */}
        <Text style={styles.headerText}>Home</Text>
        </TouchableOpacity>
      </View>
      {/* <View style={styles.icon}> */}
        <View style={styles.headerText}>
        <TouchableOpacity onPress={() => navigation.navigate("UserProfile")}>
          {/* <Icon name="user" type="font-awesome-5" /> */}
          <Text style={styles.headerText}>Profile</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default CustomHeaderRight;
