// App.js
import "react-native-gesture-handler";
import * as React from "react";
import { useContext, useEffect } from "react";
import { View, ActivityIndicator } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import HomeScreen from "./screens/HomeScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import GamesScreen from "./screens/GamesScreen";
import GameDetails from "./screens/GameDetails";
import AddNewGame from "./screens/AddNewGame";
import EditGame from "./screens/EditGame";
import TeamsScreen from "./screens/TeamsScreen";
import AddNewTeam from "./screens/AddNewTeam";
import TeamDetailsScreen from "./screens/TeamDetailsScreen";
import EditTeam from "./screens/EditTeam";
import AddNewPlayer from "./screens/AddNewPlayer";
import PlayersScreen from "./screens/PlayersScreen";
import PlayerDetailsScreen from "./screens/PlayerDetailsScreen";
import EditPlayerScreen from "./screens/EditPlayerScreen";
import EditHomeLineup from "./screens/EditHomeLineup";
import EditAwayLineup from "./screens/EditAwayLineup";
import BoxScore from "./screens/BoxScore/BoxScore";
import ScoreNextInning from "./screens/ScoreNextInning/ScoreNextInning";
import AtBatEditScreen from "./screens/AtBatEditScreen";
import TeamStatsMainScreen from "./screens/Stats/TeamStatsMainScreen";
import StatsMainScreen from "./screens/Stats/StatsMainScreen"; // Import the StatsMainScreen
import TeamStatsScreen from "./screens/Stats/TeamStatsScreen"; // Import the TeamStatsScreen
import PlayerStatsMainScreen from "./screens/Stats/PlayerStatsMainScreen"; // Import the PlayerStatsMainScreen
import PlayerStatsScreen from "./screens/Stats/PlayerStatsScreen"; // Import the PlayerStatsScreen
import ChangePasswordScreen from "./screens/ChangePasswordScreen";
import UserProfile from "./screens/UserProfile";
import CustomHeaderRight from "./components/CustomHeaderRight";
import AuthContext, { AuthProvider } from "./AuthContext";
import { enableScreens } from "react-native-screens";
import { SafeAreaProvider } from "react-native-safe-area-context"; // Ensure this is imported
import SeasonStatsMainScreen from "./screens/Stats/SeasonStatsMainScreen";
import SprayChartScreen from "./screens/Stats/SprayChartScreen.js";

enableScreens();

const Stack = createStackNavigator();

function App2() {
  const { user, loading, checkToken } = useContext(AuthContext);

  useEffect(() => {
    checkToken();
  }, []);

  if (loading) {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName={user ? "Home" : "Login"}>
        {user ? (
          <>
            <Stack.Screen
              name="Home"
              component={HomeScreen}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="Games"
              component={GamesScreen}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="GameDetails"
              component={GameDetails}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="AddNewGame"
              component={AddNewGame}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="EditGame"
              component={EditGame}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="Teams"
              component={TeamsScreen}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="AddNewTeam"
              component={AddNewTeam}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="TeamDetails"
              component={TeamDetailsScreen}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="EditTeam"
              component={EditTeam}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="AddNewPlayer"
              component={AddNewPlayer}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="Players"
              component={PlayersScreen}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="PlayerDetails"
              component={PlayerDetailsScreen}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="EditPlayer"
              component={EditPlayerScreen}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="EditHomeLineup"
              component={EditHomeLineup}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="EditAwayLineup"
              component={EditAwayLineup}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="BoxScore"
              component={BoxScore}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="ScoreNextInning"
              component={ScoreNextInning}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="AtBatEdit"
              component={AtBatEditScreen}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="StatsMain"
              component={StatsMainScreen}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="TeamStatsMain"
              component={TeamStatsMainScreen}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="TeamStats"
              component={TeamStatsScreen}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />

            <Stack.Screen
              name="SeasonStats"
              component={SeasonStatsMainScreen}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="PlayerStatsMain"
              component={PlayerStatsMainScreen}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="PlayerStats"
              component={PlayerStatsScreen}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="SprayChart"
              component={SprayChartScreen}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="ChangePassword"
              component={ChangePasswordScreen}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
            <Stack.Screen
              name="UserProfile"
              component={UserProfile}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
          </>
        ) : (
          <>
            <Stack.Screen name="Login" component={LoginScreen} />
            <Stack.Screen name="Register" component={RegisterScreen} />
            <Stack.Screen
              name="Home"
              component={HomeScreen}
              options={({ navigation }) => ({
                headerRight: () => (
                  <CustomHeaderRight navigation={navigation} />
                ),
              })}
            />
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default function App() {
  return (
    <AuthProvider>
      <App2 />
    </AuthProvider>
  );
}
