// screens/EditTeam.js
import React, { useState, useEffect } from "react";
import { View, Text, TextInput, Button, StyleSheet, Alert } from "react-native";
import axios from "axios";
import { BASE_URL } from "@env";
import { useNavigation, useRoute } from "@react-navigation/native";
import showAlert from "../utils/showAlert";
import styles from "./styles";

const EditTeam = () => {
  const route = useRoute();
  const { teamId } = route.params;
  const [teamName, setTeamName] = useState("");
  const [season, setSeason] = useState("");
  const navigation = useNavigation();

  useEffect(() => {
    fetchTeamDetails();
  }, []);

  const fetchTeamDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/team/${teamId}`);
      const team = response.data;
      setTeamName(team.name);
      setSeason(team.season.toString());
    } catch (error) {
      console.error("Error fetching team details:", error);
      showAlert("Error", "There was an error fetching the team details.");
    }
  };

  const saveTeam = async () => {
    if (!teamName) {
      showAlert("Validation Error", "Please enter a team name.");
      return;
    }
    try {
      const updatedTeam = { name: teamName, season: parseInt(season) };
      await axios.put(`${BASE_URL}/api/team/${teamId}`, updatedTeam);
      showAlert("Team Updated", "The team has been updated successfully.", [
        {
          text: "OK",
          onPress: () => navigation.navigate("Teams", { refresh: true }),
        },
      ]);
    } catch (error) {
      console.error("Error updating team:", error);
      showAlert("Error", "There was an error updating the team.");
    }
  };

  return (
    <View style={styles.container}>
      <Text>Team Name (required):</Text>
      <TextInput
        style={styles.input}
        value={teamName}
        onChangeText={setTeamName}
        placeholder="Enter Team Name"
      />
      <Text>Season (required):</Text>
      <TextInput
        style={styles.input}
        value={season}
        onChangeText={setSeason}
        placeholder="Enter Season"
        keyboardType="numeric"
      />
      <View style={styles.buttonContainer}>
        <Button title="Save Team" onPress={saveTeam} color="green" />
      </View>
    </View>
  );
};

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     justifyContent: "center",
//     padding: 20,
//   },
//   input: {
//     height: 40,
//     borderColor: "gray",
//     borderWidth: 1,
//     marginBottom: 12,
//     paddingHorizontal: 8,
//   },
// });

export default EditTeam;
