import React, { useState, useContext } from "react";
import { View, Text, TextInput, Button, ImageBackground } from "react-native";
import AuthContext from "../AuthContext";
import styles from "./styles";

const RegisterScreen = ({ navigation }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { register } = useContext(AuthContext);

  const handleRegister = async () => {
    try {
      await register(email, password);
      navigation.navigate("Home");
    } catch (error) {
      console.log("Register error:", error);
    }
  };

  return (
    <ImageBackground
      source={require("../assets/ScorekeepAppIcon.png")}
      style={styles.backgroundImage}
    >
      <View style={styles.overlay}>
        <Text>Email</Text>
        <TextInput
          style={[styles.input, { backgroundColor: "#eee" }]}
          value={email}
          onChangeText={setEmail}
        />
        <Text>Password</Text>
        <TextInput
          style={[styles.input, { backgroundColor: "#eee" }]}
          value={password}
          onChangeText={setPassword}
          secureTextEntry
        />
        <View style={styles.buttonRow}>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button title="Register" onPress={handleRegister} />
            </View>
          </View>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button
                title="Go to Login"
                color="green"
                onPress={() => navigation.navigate("Login")}
              />
            </View>
          </View>
        </View>
      </View>
    </ImageBackground>
  );
};

export default RegisterScreen;
