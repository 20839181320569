import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TextInput,
  Button,
  StyleSheet,
  Alert,
  TouchableOpacity,
  Platform,
  ScrollView,
} from "react-native";
import DateTimePicker from "@react-native-community/datetimepicker";
import axios from "axios";
import { BASE_URL } from "@env";
import {
  useNavigation,
  useRoute,
  useFocusEffect,
} from "@react-navigation/native";
import { Picker } from "@react-native-picker/picker";
import showAlert from "../utils/showAlert";
import styles from "./styles";

const EditGame = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { gameId, refresh, newTeam } = route.params || {};
  const [homeTeam, setHomeTeam] = useState(null);
  const [awayTeam, setAwayTeam] = useState(null);
  const [teams, setTeams] = useState([]);
  const [umpire, setUmpire] = useState("");
  const [location, setLocation] = useState("");
  const [date, setDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [season, setSeason] = useState(new Date().getFullYear().toString());
  const [type, setType] = useState("regular season");
  const [homeScore, setHomeScore] = useState("");
  const [awayScore, setAwayScore] = useState("");
  const [maxInnings, setMaxInnings] = useState("");

  useFocusEffect(
    React.useCallback(() => {
      fetchTeams();
      fetchGameDetails();
      if (newTeam) {
        setTeams((prevTeams) => [...prevTeams, newTeam]);
        if (newTeam._id === homeTeam?._id) {
          setHomeTeam(newTeam);
        } else if (newTeam._id === awayTeam?._id) {
          setAwayTeam(newTeam);
        }
      }
    }, [refresh, newTeam])
  );

  const fetchTeams = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/team`);
      setTeams(response.data.filter((team) => !team.isArchived)); // Exclude archived teams
    } catch (error) {
      console.error("Error fetching teams:", error);
      showAlert("Error", "There was an error fetching the teams.");
    }
  };

  const fetchGameDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/game/${gameId}`);
      const game = response.data;
      setHomeTeam(game.homeTeam);
      setAwayTeam(game.awayTeam);
      setUmpire(game.umpire);
      setLocation(game.location);
      setDate(new Date(game.date));
      setSeason(game.season.toString());
      setType(game.type || "regular season");
      setHomeScore(game.homeScore.toString());
      setAwayScore(game.awayScore.toString());
      setMaxInnings(game.maxInnings.toString());
    } catch (error) {
      console.error("Error fetching game details:", error);
      showAlert("Error", "There was an error fetching the game details.");
    }
  };

  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate || date;
    setShow(Platform.OS === "ios");
    setDate(currentDate);
  };

  const showDatepicker = () => {
    setShow(true);
  };

  const saveGame = async () => {
    if (!homeTeam || !awayTeam || !season) {
      showAlert("Validation Error", "Please fill in all required fields.");
      return;
    }
    try {
      const updatedGame = {
        homeTeam: homeTeam._id,
        awayTeam: awayTeam._id,
        umpire,
        location,
        date,
        homeScore: parseInt(homeScore, 10),
        awayScore: parseInt(awayScore, 10),
        maxInnings: parseInt(maxInnings, 10),
        season: parseInt(season, 10),
        type: type || undefined,
      };
      await axios.put(`${BASE_URL}/api/game/${gameId}`, updatedGame);
      showAlert("Game Updated", "The game has been updated successfully.", [
        {
          text: "OK",
          onPress: () => navigation.navigate("GameDetails", { gameId }),
        },
      ]);
    } catch (error) {
      console.error("Error updating game:", error);
      showAlert("Error", "There was an error updating the game.");
    }
  };

  return (
    <View style={styles.container}>
      <ScrollView Vertical>
        <View style={styles.row}>
          <View style={styles.column}>
            <Text>Date (required):</Text>
            <TouchableOpacity onPress={showDatepicker}>
              <Text style={styles.dateText}>{date.toLocaleDateString()}</Text>
            </TouchableOpacity>
            {show && (
              <DateTimePicker
                testID="dateTimePicker"
                value={date}
                mode="date"
                is24Hour={true}
                display="default"
                onChange={onChange}
              />
            )}
          </View>
          <View style={styles.column}>
            <Text>Season (required):</Text>
            <TextInput
              style={styles.input}
              value={season}
              onChangeText={setSeason}
              placeholder="Enter Season"
              keyboardType="numeric"
            />
          </View>
        </View>

        <Text>Home Team (required):</Text>
        <View style={styles.pickerContainer}>
          <View style={styles.pickerRow}>
            <Picker
              selectedValue={homeTeam?._id}
              onValueChange={(itemValue) =>
                setHomeTeam(teams.find((team) => team._id === itemValue))
              }
              style={styles.teamPicker}
            >
              <Picker.Item label="Select Home Team" value="" />
              {teams.map((team) => (
                <Picker.Item
                  key={team._id}
                  label={team.name}
                  value={team._id}
                />
              ))}
            </Picker>
            <TouchableOpacity
              onPress={() =>
                navigation.navigate("AddNewTeam", {
                  previousScreen: "EditGame",
                  gameId,
                })
              }
            >
              <Text style={styles.addButton}>+</Text>
            </TouchableOpacity>
          </View>
        </View>

        <Text>Away Team (required):</Text>
        <View style={styles.pickerContainer}>
          <View style={styles.pickerRow}>
            <Picker
              selectedValue={awayTeam?._id}
              onValueChange={(itemValue) =>
                setAwayTeam(teams.find((team) => team._id === itemValue))
              }
              style={styles.teamPicker}
            >
              <Picker.Item label="Select Away Team" value="" />
              {teams.map((team) => (
                <Picker.Item
                  key={team._id}
                  label={team.name}
                  value={team._id}
                />
              ))}
            </Picker>
            <TouchableOpacity
              onPress={() =>
                navigation.navigate("AddNewTeam", {
                  previousScreen: "EditGame",
                  gameId,
                })
              }
            >
              <Text style={styles.addButton}>+</Text>
            </TouchableOpacity>
          </View>
        </View>
        <Text>Umpire:</Text>
        <TextInput
          style={styles.input}
          value={umpire}
          onChangeText={setUmpire}
          placeholder="Enter Umpire"
        />
        <Text>Location:</Text>
        <TextInput
          style={styles.input}
          value={location}
          onChangeText={setLocation}
          placeholder="Enter Location"
        />
        <View style={styles.row}>
          <View style={styles.column}>
            <Text>Home Score:</Text>
            <TextInput
              style={styles.input}
              value={homeScore}
              onChangeText={setHomeScore}
              placeholder="Enter Home Score"
              keyboardType="numeric"
            />
          </View>
          <View style={styles.column}>
            <Text>Away Score:</Text>
            <TextInput
              style={styles.input}
              value={awayScore}
              onChangeText={setAwayScore}
              placeholder="Enter Away Score"
              keyboardType="numeric"
            />
          </View>
        </View>
        <View style={styles.row}>
          <View style={[styles.column, { flex: 2 }]}>
            <Text>Type of Game:</Text>
            <View style={styles.pickerContainer}>
              <Picker
                selectedValue={type}
                onValueChange={(itemValue) => setType(itemValue)}
                style={styles.picker}
              >
                <Picker.Item label="Select Type" value="" />
                <Picker.Item label="Exhibition" value="exhibition" />
                <Picker.Item label="Regular Season" value="regular season" />
                <Picker.Item label="Playoff" value="playoff" />
              </Picker>
            </View>
          </View>
          <View style={styles.column}>
            <Text>Innings</Text>
            <TextInput
              style={styles.input}
              value={maxInnings}
              onChangeText={setMaxInnings}
              placeholder="Enter # Innings"
              keyboardType="numeric"
            />
          </View>
        </View>
        <View style={styles.buttonRow}>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button title="Save Game" onPress={saveGame} color="green" />
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     justifyContent: "center",
//     padding: 20,
//   },
//   input: {
//     height: 40,
//     borderColor: "gray",
//     borderWidth: 1,
//     marginBottom: 12,
//     paddingHorizontal: 8,
//   },
//   dateText: {
//     fontSize: 18,
//     marginBottom: 12,
//     color: "blue",
//   },
//   pickerContainer: {
//     borderColor: "gray",
//     borderWidth: 1,
//     borderRadius: 5,
//     marginBottom: 12,
//   },
//   picker: {
//     height: 50,
//     width: "90%",
//   },
//   pickerRow: {
//     flexDirection: "row",
//     alignItems: "center",
//   },
//   addButton: {
//     fontSize: 24,
//     marginLeft: 10,
//     marginRight: 20,
//     color: "blue",
//   },
// });

export default EditGame;
