import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  FlatList,
  TouchableOpacity,
  Button,
} from "react-native";
import axios from "axios";
import { BASE_URL } from "@env";
import {
  useRoute,
  useNavigation,
  useFocusEffect,
} from "@react-navigation/native";
import { Picker } from "@react-native-picker/picker";
import showAlert from "../utils/showAlert";
import styles from "./styles";

const EditAwayLineup = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const { gameId } = route.params;
  const [game, setGame] = useState(null);
  const [lineup, setLineup] = useState([]);
  const [players, setPlayers] = useState({});
  const [teamPlayers, setTeamPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState("");

  useFocusEffect(
    React.useCallback(() => {
      fetchLineup();
    }, [])
  );

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      fetchLineup();
    });

    return unsubscribe;
  }, [navigation]);

  const fetchLineup = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/game/${gameId}`);
      const gameData = response.data;
      setGame(gameData);

      if (gameData.awayLineup.length > 0) {
        const playerIds = gameData.awayLineup.map((player) => player._id);
        const playerResponses = await Promise.all(
          playerIds.map((id) => axios.get(`${BASE_URL}/api/player/${id}`))
        );
        const playerData = playerResponses.reduce((acc, res) => {
          if (!res.data.isArchived) {
            acc[res.data._id] = res.data;
          }
          return acc;
        }, {});

        setPlayers(playerData);
        setLineup(playerIds);
      }

      if (gameData.awayTeam) {
        fetchTeamPlayers(
          gameData.awayTeam._id,
          gameData.awayLineup.map((player) => player._id)
        );
      }
    } catch (error) {
      console.error("Error fetching lineup:", error);
    }
  };

  const fetchTeamPlayers = async (teamId, currentLineup) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/team/${teamId}`);
      const availablePlayers = response.data.players.filter(
        (player) => !currentLineup.includes(player._id) && !player.isArchived
      ); 
      setTeamPlayers(availablePlayers);
    } catch (error) {
      console.error("Error fetching team players:", error);
    }
  };

  const addPlayerToLineup = async (playerId) => {
    if (!playerId) {
      showAlert("Validation Error", "Please select a player to add.");
      return;
    }

    try {
      const updatedLineup = [...lineup, playerId];
      await axios.put(`${BASE_URL}/api/game/${gameId}/lineup`, {
        awayLineup: updatedLineup,
      });
      setSelectedPlayer("");
      fetchLineup();
      if (game?.awayTeam) {
        fetchTeamPlayers(game.awayTeam._id, updatedLineup);
      }
    } catch (error) {
      console.error("Error adding player to lineup:", error);
      showAlert("Error", "There was an error adding the player to the lineup.");
    }
  };

  const removePlayerFromLineup = async (playerId) => {
    try {
      const updatedLineup = lineup.filter((id) => id !== playerId);
      await axios.put(`${BASE_URL}/api/game/${gameId}/lineup`, {
        awayLineup: updatedLineup,
      });
      fetchLineup();
      if (game?.awayTeam) {
        fetchTeamPlayers(game.awayTeam._id, updatedLineup);
      }
    } catch (error) {
      console.error("Error removing player from lineup:", error);
      showAlert(
        "Error",
        "There was an error removing the player from the lineup."
      );
    }
  };

  const movePlayer = async (index, direction) => {
    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= lineup.length) return;

    const updatedLineup = [...lineup];
    const [movedPlayer] = updatedLineup.splice(index, 1);
    updatedLineup.splice(newIndex, 0, movedPlayer);

    try {
      await axios.put(`${BASE_URL}/api/game/${gameId}/lineup`, {
        awayLineup: updatedLineup,
      });
      fetchLineup();
    } catch (error) {
      console.error("Error moving player in lineup:", error);
      showAlert("Error", "There was an error moving the player in the lineup.");
    }
  };

  const renderPlayer = ({ item, index }) => {
    const player = players[item];
    if (!player) return null;

    return (
      <TouchableOpacity onPress={() => navigation.navigate("EditPlayer", { playerId: item })}>
        <View style={styles.lineupPlayerContainer}>
          <View style={styles.playerInfo}>
            <Text style={styles.playerIndex}>{index + 1}.</Text>
            <Text style={styles.playerNumber}>#{player.number}</Text>
            <Text style={styles.playerName}>
              {player.firstName} {player.lastName}
            </Text>
            <Text style={styles.playerGender}>({player.gender})</Text>
          </View>
          <View style={styles.playerActions}>
            <TouchableOpacity onPress={() => movePlayer(index, -1)}>
              <Text style={styles.moveButton}>↑</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => movePlayer(index, 1)}>
              <Text style={styles.moveButton}>↓</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => removePlayerFromLineup(item)}>
              <Text style={styles.removeButton}>Remove</Text>
            </TouchableOpacity>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <View style={styles.container}>
      <Text style={styles.h4}>Edit Away Lineup</Text>
      <FlatList
        data={lineup}
        keyExtractor={(item) => item}
        renderItem={renderPlayer}
      />
      <View style={styles.pickerRow}>
        <Picker
          selectedValue={selectedPlayer}
          onValueChange={(itemValue) => {
            setSelectedPlayer(itemValue);
            if (itemValue) addPlayerToLineup(itemValue);
          }}
          style={styles.teamPicker}
        >
          <Picker.Item label="Select Player" value="" />
          {teamPlayers.map((player) => (
            <Picker.Item
              key={player._id}
              label={`${player.number} - ${player.firstName} ${player.lastName}`}
              value={player._id}
            />
          ))}
        </Picker>
      </View>
      <View style={styles.buttonContainer}>
        <Button
          title="Add New Player"
          style={styles.button}
          onPress={() =>
            navigation.navigate("AddNewPlayer", {
              teamId: game?.awayTeam._id,
              previousScreen: "EditAwayLineup",
            })
          }
        />
      </View>
    </View>
  );
};

export default EditAwayLineup;
