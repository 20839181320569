// screens/AddNewTeam.js
import React, { useState } from "react";
import { View, Text, TextInput, Button, StyleSheet, Alert } from "react-native";
import axios from "axios";
import { BASE_URL } from "@env";
import { useNavigation, useRoute } from "@react-navigation/native";
import showAlert from "../utils/showAlert";

const AddNewTeam = () => {
  const [teamName, setTeamName] = useState("");
  const [season, setSeason] = useState(new Date().getFullYear().toString());
  const navigation = useNavigation();
  const route = useRoute();
  const { previousScreen, gameId } = route.params || {};

  const checkDuplicateAndSaveTeam = async () => {
    if (!teamName) {
      showAlert("Validation Error", "Please enter a team name.");
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/api/team/check`, {
        name: teamName,
      });
      if (response.data.exists) {
        showAlert(
          "Duplicate Team",
          "A team with this name already exists. Please choose a different name."
        );
      } else {
        saveTeam();
      }
    } catch (error) {
      console.error("Error checking team name:", error);
      showAlert("Error", "There was an error checking the team name.");
    }
  };

  const saveTeam = async () => {
    try {
      const newTeam = { name: teamName, season, isArchived: false }; // Add isArchived: false
      const response = await axios.post(`${BASE_URL}/api/team/create`, newTeam);
      showAlert("Team Saved", "The team has been saved successfully.", [
        {
          text: "OK",
          onPress: () =>
            navigation.navigate(previousScreen || "Teams", {
              refresh: true,
              newTeam: response.data,
              gameId,
            }),
        },
      ]);
    } catch (error) {
      console.error("Error saving team:", error);
      showAlert("Error", "There was an error saving the team.");
    }
  };

  return (
    <View style={styles.container}>
      <Text>Team Name (required):</Text>
      <TextInput
        style={styles.input}
        value={teamName}
        onChangeText={setTeamName}
        placeholder="Enter Team Name"
      />
      <Text>Season (required):</Text>
      <TextInput
        style={styles.input}
        value={season}
        onChangeText={setSeason}
        placeholder="Enter Season"
        keyboardType="numeric"
      />
      <Button
        title="Save Team"
        onPress={checkDuplicateAndSaveTeam}
        color="green"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    padding: 20,
  },
  input: {
    height: 40,
    borderColor: "gray",
    borderWidth: 1,
    marginBottom: 12,
    paddingHorizontal: 8,
  },
});

export default AddNewTeam;
