// screens/TeamStatsScreen.js
import React, { useEffect, useState } from "react";
import { View, Text, ActivityIndicator, ScrollView } from "react-native";
import axios from "axios";
import styles from "./styles";
import { BASE_URL } from "@env";

const calculatePer8Innings = (stats) => {
  const inningsFactor = 8;
  return {
    record: stats.record,
    totalRunsFor:
      (stats.totalRunsFor / stats.totalOffensiveInnings) * inningsFactor || 0,
    totalRunsAgainst:
      (stats.totalRunsAgainst / stats.totalDefensiveInnings) * inningsFactor ||
      0,
    totalHits:
      (stats.totalHits /
        (stats.totalOffensiveInnings + stats.totalDefensiveInnings)) *
        inningsFactor || 0,
    totalMercyInnings:
      (stats.totalMercyInnings /
        (stats.totalOffensiveInnings + stats.totalDefensiveInnings)) *
        inningsFactor || 0,
  };
};

const TeamStatsScreen = ({ route }) => {
  const { teamId } = route.params;
  const [team, setTeam] = useState(null);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTeamDetails = async () => {
      try {
        const teamResponse = await axios.get(`${BASE_URL}/api/team/${teamId}`);
        setTeam(teamResponse.data);
        const statsResponse = await axios.get(
          `${BASE_URL}/api/team/${teamId}/stats`
        );
        setStats(statsResponse.data);
      } catch (error) {
        console.error("Error fetching team details and stats:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamDetails();
  }, [teamId]);

  if (loading) {
    return <ActivityIndicator size="large" color="#0000ff" />;
  }

  if (!stats || !team) {
    return (
      <View style={styles.container}>
        <Text>No stats available for this team.</Text>
      </View>
    );
  }

  const overallPer8Innings = calculatePer8Innings(stats.overall);
  const exhibitionPer8Innings = calculatePer8Innings(stats.exhibition);
  const regularSeasonPer8Innings = calculatePer8Innings(stats.regularSeason);
  const playoffPer8Innings = calculatePer8Innings(stats.playoff);

  return (
    <View style={styles.container}>
    <ScrollView>
      <Text style={styles.h3}>{team.season} - {team.name}</Text>
      
      <Text style={styles.h4}>Overall Season Stats</Text>
      <Text>Wins: {stats.overall.record.wins}</Text>
      <Text>Losses: {stats.overall.record.losses}</Text>
      <Text>
        Total Offensive Innings: {stats.overall.totalOffensiveInnings}
      </Text>
      <Text>
        Total Defensive Innings: {stats.overall.totalDefensiveInnings}
      </Text>
      <Text>Total Mercy Innings: {stats.overall.totalMercyInnings}</Text>
      <View style={styles.twoColumns}>
        <View style={styles.column}>
          <Text style={styles.h5}>Total</Text>
          <Text>Total Runs For: {stats.overall.totalRunsFor}</Text>
          <Text>Total Runs Against: {stats.overall.totalRunsAgainst}</Text>
          <Text>Total Hits: {stats.overall.totalHits}</Text>
        </View>
        <View style={styles.column}>
          <Text style={styles.h5}>Per 8 Innings</Text>
          <Text>
            Total Runs For: {overallPer8Innings.totalRunsFor.toFixed(1)}
          </Text>
          <Text>
            Total Runs Against: {overallPer8Innings.totalRunsAgainst.toFixed(1)}
          </Text>
          <Text>Total Hits: {overallPer8Innings.totalHits.toFixed(1)}</Text>
        </View>
      </View>
      <Text style={styles.h4}>Exhibition Stats</Text>
      <Text>Wins: {stats.exhibition.record.wins}</Text>
      <Text>Losses: {stats.exhibition.record.losses}</Text>
      <Text>
        Total Offensive Innings: {stats.exhibition.totalOffensiveInnings}
      </Text>
      <Text>
        Total Defensive Innings: {stats.exhibition.totalDefensiveInnings}
      </Text>
      <Text>Total Mercy Innings: {stats.exhibition.totalMercyInnings}</Text>
      <View style={styles.twoColumns}>
        <View style={styles.column}>
          <Text style={styles.h5}>Total</Text>
          <Text>Total Runs For: {stats.exhibition.totalRunsFor}</Text>
          <Text>Total Runs Against: {stats.exhibition.totalRunsAgainst}</Text>
          <Text>Total Hits: {stats.exhibition.totalHits}</Text>
        </View>
        <View style={styles.column}>
          <Text style={styles.h5}>Per 8 Innings</Text>
          <Text>
            Total Runs For: {exhibitionPer8Innings.totalRunsFor.toFixed(1)}
          </Text>
          <Text>
            Total Runs Against: {exhibitionPer8Innings.totalRunsAgainst.toFixed(1)}
          </Text>
          <Text>Total Hits: {exhibitionPer8Innings.totalHits.toFixed(1)}</Text>
        </View>
      </View>

      <Text style={styles.h4}>Regular Season Stats</Text>
      <Text>Wins: {stats.regularSeason.record.wins}</Text>
      <Text>Losses: {stats.regularSeason.record.losses}</Text>
      <Text>
        Total Offensive Innings: {stats.regularSeason.totalOffensiveInnings}
      </Text>
      <Text>
        Total Defensive Innings: {stats.regularSeason.totalDefensiveInnings}
      </Text>
      <Text>Total Mercy Innings: {stats.regularSeason.totalMercyInnings}</Text>
      <View style={styles.twoColumns}>
        <View style={styles.column}>
          <Text style={styles.h5}>Total</Text>
          <Text>Total Runs For: {stats.regularSeason.totalRunsFor}</Text>
          <Text>Total Runs Against: {stats.regularSeason.totalRunsAgainst}</Text>
          <Text>Total Hits: {stats.regularSeason.totalHits}</Text>
        </View>
        <View style={styles.column}>
          <Text style={styles.h5}>Per 8 Innings</Text>
          <Text>
            Total Runs For: {regularSeasonPer8Innings.totalRunsFor.toFixed(1)}
          </Text>
          <Text>
            Total Runs Against: {regularSeasonPer8Innings.totalRunsAgainst.toFixed(1)}
          </Text>
          <Text>Total Hits: {regularSeasonPer8Innings.totalHits.toFixed(1)}</Text>
        </View>
      </View>

      <Text style={styles.h4}>Playoff Stats</Text>
      <Text>Wins: {stats.playoff.record.wins}</Text>
      <Text>Losses: {stats.playoff.record.losses}</Text>
      <Text>
        Total Offensive Innings: {stats.playoff.totalOffensiveInnings}
      </Text>
      <Text>
        Total Defensive Innings: {stats.playoff.totalDefensiveInnings}
      </Text>
      <Text>Total Mercy Innings: {stats.playoff.totalMercyInnings}</Text>
      <View style={styles.twoColumns}>
        <View style={styles.column}>
          <Text style={styles.h5}>Total</Text>
          <Text>Total Runs For: {stats.playoff.totalRunsFor}</Text>
          <Text>Total Runs Against: {stats.playoff.totalRunsAgainst}</Text>
          <Text>Total Hits: {stats.playoff.totalHits}</Text>
        </View>
        <View style={styles.column}>
          <Text style={styles.h5}>Per 8 Innings</Text>
          <Text>
            Total Runs For: {playoffPer8Innings.totalRunsFor.toFixed(1)}
          </Text>
          <Text>
            Total Runs Against: {playoffPer8Innings.totalRunsAgainst.toFixed(1)}
          </Text>
          <Text>Total Hits: {playoffPer8Innings.totalHits.toFixed(1)}</Text>
        </View>
      </View>
    </ScrollView>
    </View>
  );
};

export default TeamStatsScreen;
