import React from "react";
import { View, Text, TouchableOpacity } from "react-native";
import styles from "./styles";

const InfoBox = ({ game, openModal, openHalfModal }) => {
  return (
    <View style={styles.infoBox}>
      <View style={styles.threeColumns}>
      <View style={[styles.column,{flex:2}]}>
      <TouchableOpacity
            onPress={() => openModal("awayScore", game.awayScore)}
          >
            <Text style={styles.text}>Away</Text>
            <Text style={styles.score}>{game.awayScore}</Text>
            <Text style={styles.text}>{game.awayTeam.name}</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.column}>
          <TouchableOpacity
            onPress={() => openModal("currentOuts", game.currentOuts)}
          >
            <Text></Text>
            <Text style={styles.text}>Outs</Text>
            <Text style={styles.selectText}>{game.currentOuts}</Text>
          </TouchableOpacity>
        </View>
        <View style={[styles.column,{flex:2}]}>
          <TouchableOpacity
            onPress={() => openModal("homeScore", game.homeScore)}
          >
            <Text style={styles.text}>Home</Text>
            <Text style={[styles.score,{backgroundColor:"blue"}]}>{game.homeScore}</Text>
            <Text style={styles.text}>{game.homeTeam.name}</Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.inningRow}>
        <TouchableOpacity onPress={() => openHalfModal(game.inningHalf)}>
          <Text style={styles.selectText}>
            {game.inningHalf === "top" ? "Top" : "Bottom"}
          </Text>
        </TouchableOpacity>
        <Text style={styles.text}> of the </Text>
        <TouchableOpacity
          onPress={() => openModal("currentInning", game.currentInning)}
        >
          <Text style={styles.selectText}>
            {game.currentInning}
            {game.currentInning === 1
              ? "st"
              : game.currentInning === 2
              ? "nd"
              : game.currentInning === 3
              ? "rd"
              : "th"}{" "}
            Inning
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default InfoBox;
