import React, { useState, useEffect } from "react";
import { View, Text, TextInput, Button, StyleSheet, Alert } from "react-native";
import axios from "axios";
import { BASE_URL } from "@env";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Picker } from "@react-native-picker/picker";
import showAlert from "../utils/showAlert";
import styles from "./styles";

const AddNewPlayer = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { teamId: routeTeamId } = route.params || {};
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("M");
  const [jerseyNumber, setJerseyNumber] = useState("");
  const [currentTeam, setCurrentTeam] = useState(routeTeamId);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    fetchTeams();
  }, []);

  const fetchTeams = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/team`);
      setTeams(response.data);
      if (!routeTeamId && response.data.length > 0) {
        setCurrentTeam(response.data[0]._id); // Default to the first team
      }
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };

  const savePlayer = async () => {
    if (!firstName || !gender || !currentTeam) {
      showAlert("Validation Error", "Please fill in all required fields.");
      return;
    }

    try {
      // Create a new player with the current team and set isArchived to false by default
      const newPlayer = {
        firstName,
        lastName,
        gender,
        number: jerseyNumber ? parseInt(jerseyNumber, 10) : undefined,
        currentTeam,
        isArchived: false, // Add this line
      };
      const response = await axios.post(
        `${BASE_URL}/api/player/create`,
        newPlayer
      );
      const playerId = response.data._id;

      // Add the player to the team
      await axios.post(`${BASE_URL}/api/team/${currentTeam}/addPlayer`, {
        playerId,
      });

      // showAlert("Player Saved", "The player has been saved successfully.", [
      //   { text: "OK", onPress: () => navigation.goBack() },
      // ]);
      navigation.goBack();
    } catch (error) {
      console.error("Error saving player:", error);
      showAlert("Error", "There was an error saving the player.");
    }
  };

  const saveAndAddAnotherPlayer = async () => {
    if (!firstName || !gender || !currentTeam) {
      showAlert("Validation Error", "Please fill in all required fields.");
      return;
    }

    try {
      // Create a new player with the current team and set isArchived to false by default
      const newPlayer = {
        firstName,
        lastName,
        gender,
        number: jerseyNumber ? parseInt(jerseyNumber, 10) : undefined,
        currentTeam,
        isArchived: false,
      };
      const response = await axios.post(
        `${BASE_URL}/api/player/create`,
        newPlayer
      );
      const playerId = response.data._id;

      // Add the player to the team
      await axios.post(`${BASE_URL}/api/team/${currentTeam}/addPlayer`, {
        playerId,
      });

      // Reset form fields
      setFirstName("");
      setLastName("");
      setGender("M");
      setJerseyNumber("");
    } catch (error) {
      console.error("Error saving player:", error);
      showAlert("Error", "There was an error saving the player.");
    }
  };

  return (
    <View style={styles.container}>
      <Text>First Name (required):</Text>
      <TextInput
        style={styles.input}
        value={firstName}
        onChangeText={setFirstName}
        placeholder="Enter First Name"
      />
      <Text>Last Name:</Text>
      <TextInput
        style={styles.input}
        value={lastName}
        onChangeText={setLastName}
        placeholder="Enter Last Name"
      />
      <Text>Gender (required):</Text>
      <View style={styles.pickerContainer}>
        <Picker
          selectedValue={gender}
          onValueChange={(itemValue, itemIndex) => setGender(itemValue)}
          style={styles.picker}
        >
          <Picker.Item label="Male" value="M" />
          <Picker.Item label="Female" value="F" />
          <Picker.Item label="Not Applicable" value="NA" />
        </Picker>
      </View>
      <Text>Jersey Number:</Text>
      <TextInput
        style={styles.input}
        value={jerseyNumber}
        onChangeText={setJerseyNumber}
        placeholder="Enter Jersey Number"
        keyboardType="numeric"
      />
      <Text>Current Team (required):</Text>
      <View style={styles.pickerContainer}>
        <Picker
          selectedValue={currentTeam}
          onValueChange={(itemValue, itemIndex) => setCurrentTeam(itemValue)}
          style={styles.picker}
        >
          {teams.map((team) => (
            <Picker.Item key={team._id} label={team.name} value={team._id} />
          ))}
        </Picker>
      </View>
      <View style={styles.buttonContainer}>
        <View style={styles.button}>
          <Button title="Save Player" onPress={savePlayer} color="green" />
        </View>
        <View style={styles.button}>
          <Button
            title="Save and Add Another"
            onPress={saveAndAddAnotherPlayer}
            color="blue"
          />
        </View>
      </View>
    </View>
  );
};

export default AddNewPlayer;
