// screens/StatsScreen.js
import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Button,
  FlatList,
  TouchableOpacity,
  ImageBackground,
} from "react-native";
import axios from "axios";
import { BASE_URL } from "@env";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import styles from "../styles";

const TeamStatsMainScreen = ({ navigation }) => {
  const [teams, setTeams] = useState([]);

  useFocusEffect(
    React.useCallback(() => {
      fetchTeams();
    }, [navigation])
  );

  const fetchTeams = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/team`);
      const activeTeams = response.data.filter((team) => !team.isArchived); // Filter out archived teams
      const sortedTeams = activeTeams.sort((a, b) => {
        if (b.season !== a.season) {
          return b.season - a.season; // Sort by season in descending order
        } else {
          return a.name.localeCompare(b.name); // Sort by team name in ascending order
        }
      });
      setTeams(sortedTeams);
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };

  return (
    <ImageBackground
      source={require("../../assets/ScorekeepAppIcon.png")}
      style={styles.backgroundImage}
    >
      <View style={styles.overlay}>
        <FlatList
          data={teams}
          keyExtractor={(item) => item._id}
          renderItem={({ item }) => (
            <TouchableOpacity
              onPress={() =>
                navigation.navigate("TeamStats", { teamId: item._id })
              }
            >
              <View style={styles.listItem}>
                <Text style={styles.h6}>
                  {item.season} - {item.name}
                </Text>
              </View>
            </TouchableOpacity>
          )}
        />
      </View>
    </ImageBackground>
  );
};

export default TeamStatsMainScreen;
