import React from "react";
import { Modal, View, Text, Button, StyleSheet } from "react-native";
import { Picker } from "@react-native-picker/picker";
import styles from "./styles";

const RunnerOutcomeModal = ({
  isVisible,
  onClose,
  onSave,
  outcome,
  setOutcome,
  runner,
}) => {
  const handleOutcomeChange = (index, value) => {
    const newOutcome = [...outcome];
    newOutcome[index] = value;
    setOutcome(newOutcome);
  };

  return (
    <Modal
      visible={isVisible}
      animationType="slide"
      transparent={true}
      onRequestClose={onClose}
    >
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          {runner.map((run, index) =>
            run ? (
              <View key={index}>
                <Text style={styles.modalTitle}>
                  Outcome for {run?.player.firstName} {run?.lastName} on{" "}
                  {index + 1}B?
                </Text>
                <Picker
                  selectedValue={outcome[index]}
                  onValueChange={(itemValue) =>
                    handleOutcomeChange(index, itemValue)
                  }
                >
                  <Picker.Item label="Select an option..." value={null} />
                  <Picker.Item label="Score" value="Score" />
                  <Picker.Item label="3B" value="3B" />
                  <Picker.Item label="2B" value="2B" />
                  <Picker.Item label="1B" value="1B" />
                  <Picker.Item label="Out" value="Out" />
                </Picker>
              </View>
            ) : null
          )}
          <View style={styles.buttonRow}>
            <View style={styles.buttonContainer}>
              <View style={styles.button}>
          <Button title="Save" onPress={onSave} color="green" />
          </View>
          </View>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
          <Button title="Cancel" onPress={onClose} color="red" />
          </View>
          </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default RunnerOutcomeModal;
