import React, { useState, useEffect } from "react";
import { View, Text, FlatList, Button, Alert } from "react-native";
import axios from "axios";
import { BASE_URL } from "@env";
import {
  useRoute,
  useFocusEffect,
  useNavigation,
} from "@react-navigation/native";
import showAlert from "../utils/showAlert";
import styles from "./styles";

const GameDetails = () => {
  const route = useRoute();
  const { gameId } = route.params;
  const [game, setGame] = useState(null);
  const navigation = useNavigation();

  useFocusEffect(
    React.useCallback(() => {
      fetchGameDetails();
    }, [route.params?.refresh])
  );

  const fetchGameDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/game/${gameId}`);
      setGame(response.data);
    } catch (error) {
      console.error(
        "Error fetching game details:",
        error.response?.data || error.message
      );
      showAlert(
        "Error",
        "There was an error fetching the game details. Please try again later."
      );
    }
  };

  const deleteGame = async () => {
    try {
      await axios.delete(`${BASE_URL}/api/game/${gameId}`);
      showAlert("Game Deleted", "The game has been deleted successfully.", [
        {
          text: "OK",
          onPress: () => navigation.navigate("Games", { refresh: true }),
        },
      ]);
    } catch (error) {
      console.error("Error deleting game:", error);
      showAlert("Error", "There was an error deleting the game.");
    }
  };

  const confirmDeleteGame = () => {
    showAlert("Confirm Delete", "Are you sure you want to delete this game?", [
      { text: "Cancel", style: "cancel" },
      { text: "Delete", style: "destructive", onPress: deleteGame },
    ]);
  };

  if (!game) {
    return (
      <View style={styles.container}>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.twoColumns}>
        <Text style={styles.listHeader}>Home Team:</Text>
        <Text style={styles.listItem}>
          {game.homeTeam ? game.homeTeam.name : "Loading..."}
        </Text>
      </View>
      <View style={styles.twoColumns}>
        <Text style={styles.listHeader}>Away Team:</Text>
        <Text style={styles.listItem}>
          {game.awayTeam ? game.awayTeam.name : "Loading..."}
        </Text>
      </View>
      <View style={styles.twoColumns}>
        <Text style={styles.listHeader}>Umpire:</Text>
        <Text style={styles.listItem}>{game.umpire}</Text>
      </View>
      <View style={styles.twoColumns}>
        <Text style={styles.listHeader}>Location:</Text>
        <Text style={styles.listItem}>{game.location}</Text>
      </View>
      <View style={styles.twoColumns}>
        <Text style={styles.listHeader}>Date:</Text>
        <Text style={styles.listItem}>
          {new Date(game.date).toLocaleDateString()}
        </Text>
      </View>
      <View style={styles.twoColumns}>
        <Text style={styles.listHeader}>Season:</Text>
        <Text style={styles.listItem}>{game.season}</Text>
      </View>
      <View style={styles.twoColumns}>
        <Text style={styles.listHeader}>Type:</Text>
        <Text style={styles.listItem}>
          {game.type === "exhibition"
            ? "Exhibition"
            : game.type === "playoffs"
            ? "Playoffs"
            : "Regular Season"}
        </Text>
      </View>
      <View style={styles.twoColumns}>
        <Text style={styles.listHeader}>Score:</Text>
        <Text style={styles.listItem}>
          {game.isFinished ? "Final" : "Incomplete"}
        </Text>
      </View>
      <View style={styles.twoColumns}>
        <Text style={styles.listHeader}>Home:</Text>
        <Text style={styles.listItem}>{game.homeScore}</Text>
      </View>
      <View style={styles.twoColumns}>
        <Text style={styles.listHeader}>Away:</Text>
        <Text style={styles.listItem}>{game.awayScore}</Text>
      </View>

      <FlatList
        data={game.innings || []}
        keyExtractor={(item, index) => `${item.number}-${item.half}-${index}`}
        renderItem={({ item }) => (
          <View>
            <Text>
              Inning {item.number} ({item.half}): Home {item.homeScore} - Away{" "}
              {item.awayScore}
            </Text>
          </View>
        )}
      />
      <FlatList
        data={
          game.innings ? game.innings.flatMap((inning) => inning.atBats) : []
        }
        keyExtractor={(item, index) => `${item._id}-${index}`}
        renderItem={({ item }) => (
          <View>
            <Text>
              Player: {item.player.firstName} {item.player.lastName}
            </Text>
            <Text>Result: {item.result}</Text>
            <Text>Location: {item.location}</Text>
            <Text>Outcome: {item.outcome}</Text>
            <Text>RBIs: {item.RBIs}</Text>
            <Text>Scored: {item.scored ? "Yes" : "No"}</Text>
          </View>
        )}
      />
      <View style={styles.buttonRow}>
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <Button
              title="Score the Game"
              onPress={() => navigation.navigate("BoxScore", { gameId })}
              color={"green"}
            />
          </View>
        </View>
      </View>
      <View style={styles.buttonRow}>
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <Button
              title="Edit Home Lineup"
              onPress={() => navigation.navigate("EditHomeLineup", { gameId })}
            />
          </View>
        </View>
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <Button
              title="Edit Away Lineup"
              onPress={() => navigation.navigate("EditAwayLineup", { gameId })}
            />
          </View>
        </View>
      </View>
      <View style={styles.buttonRow}>
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <Button
              title="Edit Game"
              onPress={() => navigation.navigate("EditGame", { gameId })}
            />
          </View>
        </View>
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <Button
              title="Delete Game"
              onPress={confirmDeleteGame}
              color="red"
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default GameDetails;
