import React, { useState, useContext } from "react";
import { View, Text, TextInput, Button, ImageBackground } from "react-native";
import AuthContext from "../AuthContext";
import showAlert from "../utils/showAlert";
import styles from "./styles";

const LoginScreen = ({ navigation }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useContext(AuthContext);

  const handleLogin = async () => {
    try {
      await login(email, password);
      navigation.navigate("Home");
    } catch (error) {
      console.log("Login error:", error);
      if (error.response.status === 400) {
        showAlert("Invalid email or password");
      }
    }
  };

  return (
    <ImageBackground
      source={require("../assets/ScorekeepAppIcon.png")}
      style={styles.backgroundImage}
    >
      <View style={styles.overlay}>
        <Text>Email</Text>
        <TextInput
          style={[styles.input, { backgroundColor: "#eee" }]}
          value={email}
          onChangeText={setEmail}
        />
        <Text>Password</Text>
        <TextInput
          style={[styles.input, { backgroundColor: "#eee" }]}
          value={password}
          onChangeText={setPassword}
          secureTextEntry
        />
        <View style={styles.buttonRow}>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button title="Login" onPress={handleLogin} />
            </View>
          </View>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button
                title="Go to Register"
                color="green"
                onPress={() => navigation.navigate("Register")}
              />
            </View>
          </View>
        </View>
      </View>
    </ImageBackground>
  );
};

export default LoginScreen;
