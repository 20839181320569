import React, { useState, useEffect, useCallback } from "react";
import { View, Text, Button, Alert, TouchableOpacity } from "react-native";
import { Picker } from "@react-native-picker/picker";
import axios from "axios";
import { BASE_URL } from "@env";
import {
  useRoute,
  useNavigation,
  useFocusEffect,
} from "@react-navigation/native";
import GameInfoBox from "./GameInfoBox";
import BaseModal from "./BaseModal";
import ScoreModal from "./ScoreModal";
import AtBatModal from "./AtBatModal";
import AtBatsTable from "./AtBatsTable";
import RunnerConflictModal from "./RunnerConflictModal";
import RunnerOutcomeModal from "./RunnerOutcomeModal";
import InningEndModal from "./InningEndModal";
import showAlert from "../../utils/showAlert";
import styles from "./styles";

const ScoreNextInning = () => {
  const {
    params: { gameId },
  } = useRoute();
  const navigation = useNavigation();
  const [game, setGame] = useState(null);
  const [bases, setBases] = useState({
    first: null,
    second: null,
    third: null,
  });
  const [currentBatter, setCurrentBatter] = useState(null);
  const [selectedBase, setSelectedBase] = useState(null);
  const [isBaseModalVisible, setBaseModalVisible] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isAtBatModalVisible, setAtBatModalVisible] = useState(false);
  const [atBatResult, setAtBatResult] = useState(null);
  const [ballLocation, setBallLocation] = useState(null);
  const [modalType, setModalType] = useState("");
  const [modalValue, setModalValue] = useState(0);
  const [initialFetchCompleted, setInitialFetchCompleted] = useState(false);
  const [currentAtBats, setCurrentAtBats] = useState([]);
  const [baseRunners, setBaseRunners] = useState({
    first: [],
    second: [],
    third: [],
  });
  const [conflictModalVisible, setConflictModalVisible] = useState(false);
  const [conflictBase, setConflictBase] = useState(null);
  const [runner1, setRunner1] = useState({});
  const [runner2, setRunner2] = useState({});
  const [runner1Outcome, setRunner1Outcome] = useState("");
  const [runner2Outcome, setRunner2Outcome] = useState("");
  const [isRunnerOutcomeModalVisible, setRunnerOutcomeModalVisible] =
    useState(false);
  const [runnerOutcome, setRunnerOutcome] = useState([]);
  const [runner, setRunner] = useState([]);
  const [currentRBIs, setCurrentRBIs] = useState([]);
  const [newAtBat, setNewAtBat] = useState(false);
  const [currentOuts, setCurrentOuts] = useState(0);
  const [isMercy, setIsMercy] = useState(false);
  const [isInningEndModalVisible, setInningEndModalVisible] = useState(false);

  useFocusEffect(
    useCallback(() => {
      fetchGameDetails();
    }, [])
  );

  useEffect(() => {
    fetchGameDetails();
  }, []);

  useEffect(() => {
    if (game && initialFetchCompleted) {
      updateBaseRunners();
      saveInningState();
    }
  }, [currentAtBats]);

  useFocusEffect(
    useCallback(() => {
      fetchGameDetails();
    }, [gameId])
  );

  useEffect(() => {
    const currentInningIndex = game?.currentInning - 1 || 1;
    const isTopHalf = game?.inningHalf === "top" || true;
    const currentInning = isTopHalf
      ? game?.awayInnings[currentInningIndex]
      : game?.homeInnings[currentInningIndex] || null;
    if (!currentInning) return;
    const currentRuns = currentInning?.runs || 0;
    if (currentRuns >= 5) {
      setIsMercy(true);
      setInningEndModalVisible(true);
    }
  }, [game]);

  useEffect(() => {
    try {
      if (newAtBat) {
        const currentAtBat = currentAtBats[currentAtBats.length - 1];
        const updatedAtBat = {
          ...currentAtBat,
          RBIs: currentRBIs + currentAtBat.RBIs,
        };
        const response = axios.put(
          `${BASE_URL}/api/atBat/${currentAtBat._id}`,
          updatedAtBat
        );
        setNewAtBat(false);
      }
    } catch (error) {
      console.error("Error updating RBIs:", error);
    }
  }, [newAtBat]);

  useEffect(() => {
    checkRunnersConflict();
  }, [baseRunners]);

  useEffect(() => {
    if (currentOuts >= 3) {
      setCurrentOuts(3);
      setInningEndModalVisible(true);
    }
  }, [currentOuts]);

  // Update the fetchGameDetails function to filter out archived at-bats
  const fetchGameDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/game/${gameId}`);
      const gameData = response.data;
      setGame(gameData);

      if (gameData) {
        const isTopInning = gameData.inningHalf === "top";
        const lineup = isTopInning ? gameData.awayLineup : gameData.homeLineup;
        const currentBatterId = isTopInning
          ? gameData.currentBatter?.away
          : gameData.currentBatter?.home;

        setCurrentBatter(
          lineup?.find((player) => player._id === currentBatterId) ||
            lineup?.[0]
        );

        const inningIndex = gameData.currentInning - 1;
        const currentInning = isTopInning
          ? gameData.awayInnings[inningIndex]
          : gameData.homeInnings[inningIndex];
        const atBats = (
          isTopInning ? currentInning.awayAtBats : currentInning.homeAtBats
        ).filter((atBat) => !atBat.isArchived); // Exclude archived at-bats

        setCurrentAtBats(atBats || []);
        setInitialFetchCompleted(true);
      }
    } catch (error) {
      console.error("Error fetching game details:", error);
    }
  };

  const checkRunnersConflict = () => {
    for (const [base, runners] of Object.entries(baseRunners)) {
      if (runners.length > 1) {
        setConflictBase(base);
        setRunner1(runners[0].atBat);
        setRunner2(runners[1].atBat);
        setConflictModalVisible(true);
        break;
      }
    }
  };

  const handleOutcomeChange = (runner, value) => {
    if (runner === "runner1") {
      setRunner1Outcome(value);
    } else {
      setRunner2Outcome(value);
    }
  };

  // Update the handleConfirmRunnerConflict function to handle archiving at-bats
  const handleConfirmRunnerConflict = async () => {
    try {
      const updateRunner = async (atBat, outcome) => {
        if (outcome === "Archive") {
          // Archive the at-bat by setting isArchived to true
          const updatedAtBat = { ...atBat, isArchived: true };
          await axios.put(
            `${BASE_URL}/api/atBat/${updatedAtBat._id}`,
            updatedAtBat
          );
        } else {
          const updatedAtBat = { ...atBat, outcome };
          await axios.put(
            `${BASE_URL}/api/atBat/${updatedAtBat._id}`,
            updatedAtBat
          );
          if (outcome === "1B")
            setBases((prev) => ({ ...prev, first: atBat.player._id }));
          else if (outcome === "2B")
            setBases((prev) => ({ ...prev, second: atBat.player._id }));
          else if (outcome === "3B")
            setBases((prev) => ({ ...prev, third: atBat.player._id }));
        }
      };

      await updateRunner(runner1, runner1Outcome);
      await updateRunner(runner2, runner2Outcome);
    } catch (error) {
      console.error("Error saving runner conflict changes:", error);
      showAlert("Error", "There was an error saving the runners' details.");
    }

    setConflictModalVisible(false);
    fetchGameDetails();
  };

  const updateBaseRunners = async () => {
    let updatedGame = { ...game };
    updatedGame.currentOuts = 0;
    setCurrentOuts(0);
    const baseRunners = { first: [], second: [], third: [] };

    // Determine the current inning
    const currentInningIndex = updatedGame.currentInning - 1;
    const isTopHalf = updatedGame.inningHalf === "top";
    const currentInning = isTopHalf
      ? updatedGame.awayInnings[currentInningIndex]
      : updatedGame.homeInnings[currentInningIndex];

    // Initialize outs in the current inning
    currentInning.outs = 0;
    currentInning.runs = 0;
    // Update base runners and outs
    currentAtBats.forEach((atBat) => {
      if (atBat.outcome === "1B") {
        baseRunners.first.push({ player: atBat.player, atBat });
      } else if (atBat.outcome === "2B") {
        baseRunners.second.push({ player: atBat.player, atBat });
      } else if (atBat.outcome === "3B") {
        baseRunners.third.push({ player: atBat.player, atBat });
      } else if (atBat.outcome === "Out") {
        currentInning.outs += 1;
      } else if (atBat.outcome === "Score") {
        currentInning.runs += 1;
      }
    });

    // Set the updated outs back to the inning in the game object
    updatedGame.currentOuts = currentInning.outs;
    setCurrentOuts(currentInning.outs);
    if (isTopHalf) {
      updatedGame.awayInnings[currentInningIndex].outs = currentInning.outs;
      updatedGame.awayInnings[currentInningIndex].runs = currentInning.runs;
    } else {
      updatedGame.homeInnings[currentInningIndex].outs = currentInning.outs;
      updatedGame.homeInnings[currentInningIndex].runs = currentInning.runs;
    }

    // Extract the necessary data to update the backend
    const updatedInning = {
      number: currentInning.number,
      half: currentInning.half,
      runs: currentInning.runs,
      outs: currentInning.outs,
      homeAtBats: currentInning.homeAtBats,
      awayAtBats: currentInning.awayAtBats,
      isMercy: currentInning.isMercy,
      firstBase: currentInning.firstBase,
      secondBase: currentInning.secondBase,
      thirdBase: currentInning.thirdBase,
    };

    const updateData = {
      homeScore: updatedGame.homeScore,
      awayScore: updatedGame.awayScore,
      currentInning: updatedGame.currentInning,
      inningHalf: updatedGame.inningHalf,
      currentOuts: updatedGame.currentOuts,
      currentBatter: updatedGame.currentBatter,
      inning: updatedInning,
      inningIndex: currentInningIndex,
      isTopHalf: isTopHalf,
    };

    // Send the update request to the backend
    await axios.put(`${BASE_URL}/api/game/${gameId}/updateInning`, updateData);

    // Update the local state
    setGame(updatedGame);
    setBaseRunners(baseRunners);
    setBases({
      first: baseRunners.first[baseRunners.first.length - 1]?.player || null,
      second: baseRunners.second[baseRunners.second.length - 1]?.player || null,
      third: baseRunners.third[baseRunners.third.length - 1]?.player || null,
    });
  };

  const openBaseModal = (base) => {
    setSelectedBase(base);
    setBaseModalVisible(true);
  };

  const closeBaseModal = () => {
    setBaseModalVisible(false);
    setSelectedBase(null);
  };

  const assignPlayerToBase = async (atBatId) => {
    const selectedAtBat = currentAtBats.find((atBat) => atBat._id === atBatId);
    if (selectedAtBat) {
      const updatedAtBat = { ...selectedAtBat };
      if (selectedBase === "first") updatedAtBat.outcome = "1B";
      else if (selectedBase === "second") updatedAtBat.outcome = "2B";
      else if (selectedBase === "third") updatedAtBat.outcome = "3B";
      await axios.put(`${BASE_URL}/api/atBat/${atBatId}`, updatedAtBat);
      fetchGameDetails();
      closeBaseModal();
    }
  };

  const openModal = (type, value) => {
    setModalType(type);
    setModalValue(value);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setModalValue(0);
  };

  const saveChanges = async () => {
    try {
      const updatedGame = { ...game };
      if (modalType === "homeScore") updatedGame.homeScore = modalValue;
      else if (modalType === "awayScore") updatedGame.awayScore = modalValue;
      else if (modalType === "currentOuts")
        updatedGame.currentOuts = modalValue;
      setCurrentOuts(modalValue);
      await axios.put(`${BASE_URL}/api/game/${gameId}/update`, updatedGame);
      setGame(updatedGame);
      closeModal();
    } catch (error) {
      console.error("Error saving changes:", error);
      showAlert("Error", "There was an error saving the changes.");
    }
  };

  const incrementValue = () => {
    setModalValue(modalValue + 1);
  };

  const decrementValue = () => {
    if (modalValue > 0) {
      setModalValue(modalValue - 1);
    }
  };

  const handleValueChange = (value) => {
    setModalValue(Number(value));
  };

  const updateCurrentBatter = async (nextBatterId) => {
    try {
      const isTopInning = game.inningHalf === "top";
      const updatedGame = { ...game };

      if (isTopInning) {
        updatedGame.currentBatter.away = nextBatterId;
      } else {
        updatedGame.currentBatter.home = nextBatterId;
      }

      await axios.put(`${BASE_URL}/api/game/${gameId}/update`, updatedGame);
      setGame(updatedGame);
      await saveInningState();
    } catch (error) {
      console.error("Error updating current batter:", error);
      showAlert("Error", "There was an error updating the current batter.");
    }
  };

  const saveInningState = async () => {
    try {
      const isTopInning = game.inningHalf === "top";
      const updatedGame = { ...game };
      const inningIndex = game.currentInning - 1;
      const inningState = {
        number: game.currentInning,
        half: game.inningHalf,
        firstBase: bases.first?._id || null,
        secondBase: bases.second?._id || null,
        thirdBase: bases.third?._id || null,
      };
      if (isTopInning) {
        if (!updatedGame.awayInnings[inningIndex]) {
          updatedGame.awayInnings[inningIndex] = inningState;
        } else {
          Object.assign(updatedGame.awayInnings[inningIndex], inningState);
        }
      } else {
        if (!updatedGame.homeInnings[inningIndex]) {
          updatedGame.homeInnings[inningIndex] = inningState;
        } else {
          Object.assign(updatedGame.homeInnings[inningIndex], inningState);
        }
      }

      await axios.put(`${BASE_URL}/api/game/${gameId}/update`, {
        homeScore: game.homeScore,
        awayScore: game.awayScore,
        currentInning: game.currentInning,
        inningHalf: game.inningHalf,
        currentOuts: game.currentOuts,
        currentBatter: game.currentBatter,
        firstBase: bases.first?._id || null,
        secondBase: bases.second?._id || null,
        thirdBase: bases.third?._id || null,
      });
      setGame(updatedGame);
    } catch (error) {
      console.error("Error saving inning state:", error);
      showAlert("Error", "There was an error saving the inning state.");
    }
  };

  const clearBase = () => {
    setBases((prev) => ({
      ...prev,
      [selectedBase]: null,
    }));
    closeBaseModal();
  };

  const handleSaveAtBat = async () => {
    setCurrentRBIs(0);
    if (!currentBatter) {
      console.error("Current batter is not set");
      return;
    }

    const checkRunnerOutcome = () => {
      if (
        baseRunners["first"].length > 0 ||
        baseRunners["second"].length > 0 ||
        baseRunners["third"].length > 0
      ) {
        setRunner([
          baseRunners["first"][0],
          baseRunners["second"][0],
          baseRunners["third"][0],
        ]);
        setRunnerOutcome(["Out", "Out", "Out"]);
        setRunnerOutcomeModalVisible(true);
        return true;
      }
      return false;
    };
    if (checkRunnerOutcome()) return;
    saveAtBat();
  };

  const saveAtBat = async () => {
    try {
      let updatedGame = { ...game };
      const isTopInning = game.inningHalf === "top";
      const inningIndex = game.currentInning - 1;
      const currentInning = isTopInning
        ? updatedGame.awayInnings[inningIndex]
        : updatedGame.homeInnings[inningIndex];

      if (!currentInning) {
        console.error("Current inning not found");
        return;
      }

      // Ensure atBats array is initialized
      if (!currentInning.atBats) {
        currentInning.atBats = [];
      }
      let outcome = "Out";
      switch (atBatResult) {
        case "Single":
          outcome = "1B";
          break;
        case "Double":
          outcome = "2B";
          break;
        case "Triple":
          outcome = "3B";
          break;
        case "Home Run":
          outcome = "Score";
          break;
        case "Fielder's Choice":
          outcome = "1B";
          break;
        default:
          outcome = "Out";
          break;
      }
      // Create the at-bat entry
      const atBatEntry = {
        game: game._id,
        inning: game.currentInning,
        inningHalf: game.inningHalf,
        // order: currentAtBats.length + 1,
        player: currentBatter._id,
        result: atBatResult,
        location: ballLocation || "NA",
        outcome: outcome,
        RBIs: outcome === "Score" ? 1 : 0,
        scored: false,
      };

      // Save the at-bat entry to the database
      await axios.post(`${BASE_URL}/api/atBat/create`, atBatEntry);
      setTimeout(() => {
        setNewAtBat(true);
      }, 2000);

      // Fetch updated game details
      await fetchGameDetails();

      // Move to the next batter
      const currentLineup = isTopInning
        ? updatedGame.awayLineup
        : updatedGame.homeLineup;
      const currentBatterIndex = currentLineup.findIndex(
        (player) => player._id === currentBatter._id
      );
      const onDeck =
        currentLineup[(currentBatterIndex + 1) % currentLineup.length];

      setCurrentBatter(onDeck);
      updatedGame.currentBatter[isTopInning ? "away" : "home"] = onDeck._id;

      setGame(updatedGame);

      // Close the AtBatModal
      setAtBatModalVisible(false);
      setAtBatResult("");
      setBallLocation("");
    } catch (error) {
      console.error("Error saving at-bat result:", error);
      showAlert("Error", "There was an error saving the at-bat result.");
    }
  };

  // Update the handleRunnerOutcomeSave function to handle archiving at-bats
  const handleRunnerOutcomeSave = async () => {
    try {
      const baseKeys = ["first", "second", "third"];
      for (let i = 0; i < baseKeys.length; i++) {
        const base = baseKeys[i];
        const runner = baseRunners[base][0];
        if (runner) {
          const updatedAtBat = { ...runner.atBat };
          updatedAtBat.outcome = runnerOutcome[i];
          if (runnerOutcome[i] === "Archive") {
            updatedAtBat.isArchived = true; // Archive the at-bat
          } else if (runnerOutcome[i] === "Score") {
            updatedAtBat.scored = true;
            setCurrentRBIs((prev) => prev + 1);
          }
          await axios.put(
            `${BASE_URL}/api/atBat/${runner.atBat._id}`,
            updatedAtBat
          );
        }
      }
      setRunnerOutcomeModalVisible(false);
      saveAtBat();
    } catch (error) {
      console.error("Error saving runner outcome:", error);
      showAlert("Error", "There was an error saving the runner outcome.");
    }
  };

  const handleInningEndSave = async (score, mercy) => {
    try {
      let updatedGame = { ...game };
      const isTopInning = game.inningHalf === "top";
      const inningIndex = game.currentInning - 1;
      const currentInning = isTopInning
        ? updatedGame.awayInnings[inningIndex]
        : updatedGame.homeInnings[inningIndex];

      if (!currentInning) {
        console.error("Current inning not found");
        return;
      }

      // Update the score and mercy rule in the current inning
      currentInning.runs = score;
      currentInning.isMercy = mercy;

      if (isTopInning) {
        updatedGame.awayInnings[inningIndex] = currentInning;
        updatedGame.inningHalf = "bottom";
        updatedGame.currentOuts = 0;
      } else {
        updatedGame.homeInnings[inningIndex] = currentInning;
        updatedGame.inningHalf = "top";
        updatedGame.currentOuts = 0;
        updatedGame.currentInning++;
      }

      const updateData = {
        runs: score,
        currentInning: updatedGame.currentInning,
        inningHalf: updatedGame.inningHalf,
        currentOuts: updatedGame.currentOuts,
        currentBatter: updatedGame.currentBatter,
        isTopHalf: isTopInning,
        inningIndex: inningIndex,
        isMercy: mercy,
      };

      const response = await axios.put(
        `${BASE_URL}/api/game/${game._id}/updateInning`,
        updateData
      );
      if (response.status === 200) {
        setGame(updatedGame);
        setInningEndModalVisible(false);

        navigation.navigate("BoxScore", { gameId: game._id });
      } else {
        throw new Error("Failed to update the game");
      }
    } catch (error) {
      console.error("Error saving inning end:", error);
      showAlert("Error", "There was an error saving the inning end.");
    }
  };

  const handleInningEndClose = () => {
    setInningEndModalVisible(false);
  };

  if (!game) {
    return (
      <View style={styles.container}>
        <Text>Loading...</Text>
      </View>
    );
  }

  const currentLineup =
    game.inningHalf === "top" ? game.awayLineup : game.homeLineup;

  const handleBatterChange = (itemValue) => {
    const nextBatter = currentLineup?.find(
      (player) => player._id === itemValue
    );
    setCurrentBatter(nextBatter);
    updateCurrentBatter(itemValue);
  };

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <GameInfoBox game={game} openModal={openModal} />
        <View style={styles.box}>
          <View style={styles.diamond}>
            {["first", "second", "third"].map((base) => (
              <TouchableOpacity
                key={base}
                style={[styles.base, styles[`${base}Base`]]}
                onPress={() => openBaseModal(base)}
              >
                <Text style={styles.baseText}>
                  {bases[base] ? bases[base].number : ""}
                </Text>
              </TouchableOpacity>
            ))}
            <View style={[styles.base, styles.homeBase]}>
              <Text style={styles.baseText}>Home</Text>
            </View>
          </View>
        </View>
      </View>
      <AtBatsTable atBats={currentAtBats} />

      <View style={styles.atBatRow}>
        <View style={styles.atBatColumn}>
          <Text style={styles.h5}>Up to Bat:</Text>
          <View style={styles.picker}>
            <Picker
              selectedValue={currentBatter?._id}
              onValueChange={handleBatterChange}
            >
              {currentLineup?.map((player) => (
                <Picker.Item
                  key={player._id}
                  label={`#${player.number} - ${player.firstName} ${player.lastName}`}
                  value={player._id}
                />
              ))}
            </Picker>
          </View>
          <Text style={styles.h5}>On Deck:</Text>
          <Text style={styles.onDeckText}>
            #
            {
              currentLineup?.[
                (currentLineup.findIndex(
                  (player) => player._id === currentBatter?._id
                ) +
                  1) %
                  currentLineup.length
              ]?.number
            }{" "}
            -
            {
              currentLineup?.[
                (currentLineup.findIndex(
                  (player) => player._id === currentBatter?._id
                ) +
                  1) %
                  currentLineup.length
              ]?.firstName
            }{" "}
            {
              currentLineup?.[
                (currentLineup.findIndex(
                  (player) => player._id === currentBatter?._id
                ) +
                  1) %
                  currentLineup.length
              ]?.lastName
            }
          </Text>
        </View>
        <View style={styles.atBatColumn}>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button
                title="Enter At Bat"
                onPress={() => setAtBatModalVisible(true)}
                color={"blue"}
              />
            </View>
          </View>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button
                title="Player Stats"
                onPress={() =>
                  navigation.navigate("PlayerStats", {
                    playerId: currentBatter?._id,
                    season: game?.season,
                    team: game?.homeTeam?._id,
                  })
                }
              />
            </View>
          </View>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button
                title="Spray Chart"
                color="green"
                onPress={() =>
                  navigation.navigate("SprayChart", {
                    playerId: currentBatter?._id,
                    season: game?.season,
                    team: game?.homeTeam?._id,
                  })
                }
              />
            </View>
          </View>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button
                title="End Inning"
                onPress={() => setInningEndModalVisible(true)}
                color={"red"}
              />
            </View>
          </View>
        </View>
      </View>
      <BaseModal
        isBaseModalVisible={isBaseModalVisible}
        closeBaseModal={closeBaseModal}
        currentAtBats={currentAtBats}
        assignPlayerToBase={assignPlayerToBase}
        clearBase={clearBase}
      />
      <ScoreModal
        isModalVisible={isModalVisible}
        closeModal={closeModal}
        modalType={modalType}
        modalValue={modalValue}
        handleValueChange={handleValueChange}
        incrementValue={incrementValue}
        decrementValue={decrementValue}
        saveChanges={saveChanges}
      />
      <AtBatModal
        isVisible={isAtBatModalVisible}
        onClose={() => setAtBatModalVisible(false)}
        onSave={handleSaveAtBat}
        atBatResult={atBatResult}
        setAtBatResult={setAtBatResult}
        ballLocation={ballLocation}
        setBallLocation={setBallLocation}
      />
      <RunnerConflictModal
        modalVisible={conflictModalVisible}
        runner1={runner1}
        runner2={runner2}
        runner1Outcome={runner1Outcome}
        runner2Outcome={runner2Outcome}
        conflictBase={conflictBase}
        handleOutcomeChange={handleOutcomeChange}
        handleConfirm={handleConfirmRunnerConflict}
        onClose={() => setConflictModalVisible(false)}
      />
      <RunnerOutcomeModal
        isVisible={isRunnerOutcomeModalVisible}
        onClose={() => setRunnerOutcomeModalVisible(false)}
        onSave={handleRunnerOutcomeSave}
        outcome={runnerOutcome}
        setOutcome={setRunnerOutcome}
        runner={runner}
      />
      <InningEndModal
        isVisible={isInningEndModalVisible}
        onClose={handleInningEndClose}
        onSave={handleInningEndSave}
        initialIsMercy={isMercy}
        inning={
          game.inningHalf === "top"
            ? game.awayInnings[game.currentInning - 1]
            : game.homeInnings[game.currentInning - 1]
        }
      />
    </View>
  );
};

export default ScoreNextInning;
