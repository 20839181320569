// screens/HomeScreen.js
import React, { useContext, useEffect } from "react";
import { View, Button, ImageBackground, ActivityIndicator } from "react-native";
import styles from "./styles";
import AuthContext from "../AuthContext";

const HomeScreen = ({ navigation }) => {
  const { user, logout, loading } = useContext(AuthContext);

  useEffect(() => {
    if (!user && !loading) {
      navigation.replace("Login");
    }
  }, [user, loading]);

  if (loading) {
    return <ActivityIndicator size="large" color="#0000ff" />;
  }

  if (!user) {
    return null;
  }

  return (
    <ImageBackground
      source={require("../assets/ScorekeepAppIcon.png")}
      style={styles.backgroundImage}
    >
      <View style={styles.overlay}>
        <View style={styles.buttonRow}>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button
                title="Games"
                onPress={() => navigation.navigate("Games")}
              />
            </View>
          </View>
        </View>
        <View style={styles.buttonRow}>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button
                title="Teams"
                onPress={() => navigation.navigate("Teams")}
              />
            </View>
          </View>
        </View>
        <View style={styles.buttonRow}>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button
                title="Players"
                onPress={() => navigation.navigate("Players")}
              />
            </View>
          </View>
        </View>
        <View style={styles.buttonRow}>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button
                title="Stats"
                onPress={() => navigation.navigate("StatsMain")}
              />
            </View>
          </View>
        </View>
        <View style={styles.buttonRow}>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button title="Logout" color="red" onPress={logout} />
            </View>
          </View>
        </View>
      </View>
    </ImageBackground>
  );
};

export default HomeScreen;
