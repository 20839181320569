// screens/ScoreNextInning/RunnerConflictModal.js
import React from "react";
import { View, Modal, Button, StyleSheet,Text } from "react-native";
import { Picker } from "@react-native-picker/picker";

const RunnerConflictModal = ({
  modalVisible,
  runner1,
  runner2,
  runner1Outcome,
  runner2Outcome,
  conflictBase,
  handleOutcomeChange,
  handleConfirm,
  onClose,
}) => {

  return (
    <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={onClose}
      >
        <View style={styles.modalView}>
          <Text>Conflict on {conflictBase} base</Text>
          
          <Text>{runner1.player?`${runner1.player.number} - ${runner1.player.firstName} ${runner1.player.lastName} Outcome:`:"Loading..."}</Text>
          <Picker
            selectedValue={runner1Outcome}
            style={styles.picker}
            onValueChange={(value) => handleOutcomeChange('runner1', value)}
          >
            <Picker.Item label="Select outcome" value="" />
            <Picker.Item label="1B" value="1B" />
            <Picker.Item label="2B" value="2B" />
            <Picker.Item label="3B" value="3B" />
            <Picker.Item label="Score" value="Score" />
            <Picker.Item label="Out" value="Out" />
          </Picker>

          <Text>{runner2.player?`${runner2.player.number} - ${runner2.player.firstName} ${runner2.player.lastName} Outcome:`:"Loading..."}</Text>
          <Picker
            selectedValue={runner2Outcome}
            style={styles.picker}
            onValueChange={(value) => handleOutcomeChange('runner2', value)}
          >
            <Picker.Item label="Select outcome" value="" />
            <Picker.Item label="1B" value="1B" />
            <Picker.Item label="2B" value="2B" />
            <Picker.Item label="3B" value="3B" />
            <Picker.Item label="Score" value="Score" />
            <Picker.Item label="Out" value="Out" />
          </Picker>

          <Button title="Confirm" onPress={handleConfirm} />
        </View>
      </Modal>
      );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  picker: {
    height: 50,
    width: 150,
  },
});

export default RunnerConflictModal;
