import { StyleSheet } from "react-native";

export default StyleSheet.create({
  icon: {
    width: 24,
    height: 24,
    resizeMode: "contain",
    flexDirection: "row",
    marginLeft: 10,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: 20,
  },
  headerText:{
    color: '#222',
    fontSize: 16,
    marginLeft: 10,
  }
})