import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
  },
  infoBox: {
    marginBottom: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
  },
  table: {
    flexDirection: "column",
  },
  row: {
    flexDirection: "row",
  },
  cell: {
    width: 50,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#ccc",
    textAlign: "center",
    textAlignVertical: "center",
  },
  teamNameCell:{
    width: 100,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#ccc",
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 14,
    fontWeight: "bold",
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalContent: {
    width: 300,
    padding: 20,
    backgroundColor: "white",
    borderRadius: 10,
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  input: {
    height: 40,
    borderColor: "gray",
    borderWidth: 1,
    flex: 1,
    paddingHorizontal: 10,
    marginBottom: 10,
  },
  incrementDecrementContainer: {
    flexDirection: "column",
    alignItems: "center",
    marginLeft: 10,
  },
  incrementDecrementButton: {
    width: 30,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "gray",
    marginVertical: 2,
  },
  incrementDecrementText: {
    fontSize: 20,
  },
  inningRow: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
  },
  buttonContainer: {
    marginTop: 20,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  button: {
    paddingVertical: 10,
    // paddingHorizontal: 20,
    borderRadius: 5,
    minWidth: 150,
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    textAlign: "center",
  },
  endGameButton: {
    backgroundColor: "green",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginVertical: 5,
  },
  cancelButton: {
    backgroundColor: "red",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginVertical: 5,
  },
  verticalButton: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 120,
    width: 50,
    borderWidth: 1,
    borderColor: "#ccc"
  },
  threeColumns: {
    flexDirection: "row",
    width: "100%",
  },
  column: {
    flex: 1,
    width: "33.33%",
    padding: 10,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
  },
  selectText: {
    fontSize: 18,
    color: "blue",
    textAlign: "center",
  },
  text: {
    fontSize: 18,
    color: "black",
    textAlign: "center",
  },
  score: {
    fontSize: 24,
    color: "black",
    backgroundColor: "green",
    color: "white",
    textAlign: "center",
    marginHorizontal: 40,
    marginVertical: 2,
  },
  buttonRow: {
    flexDirection: "row",
    // flexWrap: "wrap",
    // width: "100%",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: 10,
    marginBottom: 10,
  },
});
