// UserProfile.js
import React from "react";
import { View, Text, Button, ImageBackground } from "react-native";
import { useContext } from "react";
import AuthContext from "../AuthContext";
import styles from "./styles";

const UserProfile = ({ navigation }) => {
  const { user, logout } = useContext(AuthContext);

  return (
    <ImageBackground
    source={require("../assets/ScorekeepAppIcon.png")}
    style={styles.backgroundImage}
  >
    <View style={styles.overlay}>
      <Text style={styles.h3}>User Profile</Text>
      <Text style={styles.h4}>
        Email: {user?.email}
        {"\n"}
        Password: ***********
        {"\n"}
      </Text>
      <View style={styles.buttonRow}>
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <Button
              title="Change Password"
              onPress={() => navigation.navigate("ChangePassword")}
            />
          </View>
        </View>
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <Button title="Logout" onPress={logout} color={"red"} />
          </View>
        </View>
      </View>
    </View>
    </ImageBackground>
  );
};

export default UserProfile;
