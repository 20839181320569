import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Modal,
  Button,
  TextInput,
  Switch,
  StyleSheet,
} from "react-native";
import styles from "./styles";

const InningEndModal = ({
  isVisible,
  onClose,
  onSave,
  initialIsMercy,
  inning,
}) => {
  const [score, setScore] = useState(inning?.runs||0);
  const [mercy, setMercy] = useState(initialIsMercy);

  useEffect(() => {
    setScore(inning?.runs||0);
    if (inning?.runs >=5) {
      setMercy(true);
    }
  }, [inning?.runs]);

  const handleSave = () => {
    onSave(score, mercy);
  };

  return (
    <Modal visible={isVisible} animationType="slide" transparent={true}>
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>End of Inning</Text>
          <Text style={styles.h6}>Score for this inning:</Text>
          <TextInput
            style={styles.input}
            keyboardType="numeric"
            value={String(score)}
            onChangeText={(text) => setScore(Number(text))}
          />
          <View style={styles.switchContainer}>
            <Text style={styles.h6}>Inning ended in a mercy rule:</Text>
            <Switch value={mercy} onValueChange={setMercy} />
          </View>
          <View style={styles.buttonContainer}>
          <View style={styles.button}>
          <Button title="Save" onPress={handleSave} color="green" />
          </View>
          <View style={styles.button}>
          <Button  title="Close" onPress={onClose} />
        </View>
        </View>
        </View>
      </View>
    </Modal>
  );
};

// const styles = StyleSheet.create({
//   modalContainer: {
//     flex: 1,
//     justifyContent: "center",
//     alignItems: "center",
//     backgroundColor: "rgba(0, 0, 0, 0.5)",
//   },
//   modalContent: {
//     width: 300,
//     padding: 20,
//     backgroundColor: "white",
//     borderRadius: 10,
//   },
//   modalTitle: {
//     fontSize: 18,
//     marginBottom: 10,
//   },
//   input: {
//     borderBottomWidth: 1,
//     marginBottom: 20,
//   },
//   switchContainer: {
//     flexDirection: "row",
//     alignItems: "center",
//     marginBottom: 0,
//   },
//   button: {
//     paddingVertical: 10,
//     paddingHorizontal: 20,
//     borderRadius: 5,
//     width: 150,
//   },
//   buttonContainer: {
//     marginTop: 20,
//     alignItems: "center",
//     flexDirection: "row",
//     justifyContent: "space-around",
//   },
// });

export default InningEndModal;
