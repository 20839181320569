import React from "react";
import { View, Text, Button, Modal } from "react-native";
import { Picker } from "@react-native-picker/picker";
import styles from "./styles";

const IsHalfModal = ({
  isHalfModalVisible,
  closeHalfModal,
  saveHalfChanges,
  halfValue,
  setHalfValue,
}) => {
  return (
    <Modal
      visible={isHalfModalVisible}
      transparent={true}
      animationType="slide"
      onRequestClose={closeHalfModal}
    >
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>Select Inning Half</Text>
          <Picker
            selectedValue={halfValue}
            onValueChange={(itemValue) => setHalfValue(itemValue)}
          >
            <Picker.Item label="Top" value="top" />
            <Picker.Item label="Bottom" value="bottom" />
          </Picker>
          <View style={styles.buttonRow}>
            <View style={styles.button}>
              <Button
                title="Save"
                onPress={saveHalfChanges}
                color="green"
                styles={styles.button}
              />
            </View>
            <View style={styles.button}>
              <Button
                title="Cancel"
                onPress={closeHalfModal}
                color="red"
                styles={styles.button}
              />
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default IsHalfModal;
