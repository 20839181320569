import React, { useState, useEffect } from 'react';
import { View, Text, Button, ActivityIndicator, ImageBackground, StyleSheet } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import axios from 'axios';
import { BASE_URL } from '@env';
import styles from './styles';

const PlayerStatsMainScreen = ({ navigation }) => {
  const [seasons, setSeasons] = useState([]);
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedSeason, setSelectedSeason] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedPlayer, setSelectedPlayer] = useState('');

  useEffect(() => {
    const fetchSeasons = async () => {
      try {
        const teamsResponse = await axios.get(`${BASE_URL}/api/team`);
        const uniqueSeasons = [...new Set(teamsResponse.data.map(team => team.season))];
        setSeasons(uniqueSeasons);
      } catch (error) {
        console.error('Error fetching seasons:', error);
      } finally {
        setLoading(false);  // Ensure loading is set to false after fetching seasons
      }
    };

    fetchSeasons();
  }, []);

  useEffect(() => {
    if (selectedSeason) {
      const fetchTeams = async () => {
        try {
          setLoading(true);  // Set loading to true when fetching teams
          const teamsResponse = await axios.get(`${BASE_URL}/api/team`);
          const filteredTeams = teamsResponse.data.filter(team => team.season === parseInt(selectedSeason));
          setTeams(filteredTeams);
        } catch (error) {
          console.error('Error fetching teams:', error);
        } finally {
          setLoading(false);  // Ensure loading is set to false after fetching teams
        }
      };

      fetchTeams();
    }
  }, [selectedSeason]);

  useEffect(() => {
    if (selectedTeam) {
      const fetchPlayers = async () => {
        try {
          setLoading(true);  // Set loading to true when fetching players
          const teamResponse = await axios.get(`${BASE_URL}/api/team/${selectedTeam}`);
          setPlayers(teamResponse.data.players);
        } catch (error) {
          console.error('Error fetching players:', error);
        } finally {
          setLoading(false);  // Ensure loading is set to false after fetching players
        }
      };

      fetchPlayers();
    }
  }, [selectedTeam]);

  const handleViewStats = () => {
    navigation.navigate('PlayerStats', { playerId: selectedPlayer, 
      season: selectedSeason, team: selectedTeam,

    });
  };

  const handleViewSprayChart = () => {
    navigation.navigate('SprayChart', { playerId: selectedPlayer,season: selectedSeason, team: selectedTeam });
  };

  if (loading) {
    return <ActivityIndicator size="large" color="#0000ff" />;
  }

  return (
    <ImageBackground
      source={require("../../assets/ScorekeepAppIcon.png")}
      style={styles.backgroundImage}
    >
      <View style={styles.overlay}>
        <View style={localStyles.pickerContainer}>
          <Text style={localStyles.label}>Select Season</Text>
          <Picker
            selectedValue={selectedSeason}
            onValueChange={(itemValue) => setSelectedSeason(itemValue)}
            style={localStyles.picker}
          >
            <Picker.Item label="Select Season" value="" />
            {seasons.map((season) => (
              <Picker.Item key={season} label={`${season}`} value={season} />
            ))}
          </Picker>
        </View>

        <View style={localStyles.pickerContainer}>
          <Text style={localStyles.label}>Select Team</Text>
          <Picker
            selectedValue={selectedTeam}
            onValueChange={(itemValue) => setSelectedTeam(itemValue)}
            enabled={!!selectedSeason}
            style={localStyles.picker}
          >
            <Picker.Item label="Select Team" value="" />
            {teams.map((team) => (
              <Picker.Item key={team._id} label={team.name} value={team._id} />
            ))}
          </Picker>
        </View>

        <View style={localStyles.pickerContainer}>
          <Text style={localStyles.label}>Select Player</Text>
          <Picker
            selectedValue={selectedPlayer}
            onValueChange={(itemValue) => setSelectedPlayer(itemValue)}
            enabled={!!selectedTeam}
            style={localStyles.picker}
          >
            <Picker.Item label="Select Player" value="" />
            {players.map((player) => (
              <Picker.Item key={player._id} label={`${player.firstName} ${player.lastName} (#${player.number})`} value={player._id} />
            ))}
          </Picker>
        </View>

        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <Button title="See Stats" onPress={handleViewStats} disabled={!selectedPlayer} />
          </View>
          <View style={styles.button}>
            <Button title="Spray Chart" onPress={handleViewSprayChart} disabled={!selectedPlayer} color="green"/>
          </View>
        </View>
      </View>
    </ImageBackground>
  );
};

const localStyles = StyleSheet.create({
  pickerContainer: {
    marginVertical: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    overflow: 'hidden',
    backgroundColor: 'white',
  },
  picker: {
    height: 50,
  },
  label: {
    margin: 10,
    fontWeight: 'bold',
  },
});

export default PlayerStatsMainScreen;
