import React from "react";
import {
  Button,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import styles from "./styles";

const ScoreBoard = ({ 
  game,
  handleScoreInning,
  handleAddInning,
 }) => {
  return (
    <ScrollView horizontal>
    <View style={styles.table}>
      <View style={styles.row}>
        <Text style={styles.teamNameCell}>Inning</Text>
        {Array.from({ length: game?.maxInnings||8 }, (_, i) => i + 1).map((inning) => (
          <Text key={inning} style={styles.cell}>
            {inning}
          </Text>
        ))
        }
      </View>
      <View style={styles.row}>
        <Text style={styles.teamNameCell}>{game.awayTeam.name}</Text>
        {Array.from({ length: game?.maxInnings||8 }, (_, i) => i + 1).map((inning, index) => (
          <View key={`Top - ${index}`}>
            <TouchableOpacity
              onPress={() => handleScoreInning(inning, "top")}
            >
              <Text key={index} style={styles.cell}>
                {game.awayInnings &&
                game.awayInnings[inning - 1] &&
                game.awayInnings[inning - 1].runs
                  ? game.awayInnings[inning - 1].runs
                  : 0}
              </Text>
            </TouchableOpacity>
          </View>
        ))}
      </View>
      <View style={styles.row}>
        <Text style={styles.teamNameCell}>{game.homeTeam.name}</Text>
        {Array.from({ length: game?.maxInnings||8 }, (_, i) => i + 1).map((inning, index) => (
          <View key={`Bottom - ${index}`}>
            <TouchableOpacity
              onPress={() => handleScoreInning(inning, "bottom")}
            >
              <Text key={index} style={styles.cell}>
                {game.homeInnings &&
                game.homeInnings[inning - 1] &&
                game.homeInnings[inning - 1].runs
                  ? game.homeInnings[inning - 1].runs
                  : 0}
              </Text>
            </TouchableOpacity>
          </View>
        ))}
      </View>
    </View>
    <View style = {styles.verticalButton}>
      <Button title="+ Inn" onPress={()=>handleAddInning()}/>
      </View>
  </ScrollView>
  );
};

export default ScoreBoard;
