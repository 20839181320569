import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Modal,
  TextInput,
  Button,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import DateTimePicker from "@react-native-community/datetimepicker";

const EndGameModal = ({
  isEndGameModalVisible,
  closeEndGameModal,
  game,
  endGame,
}) => {
  const [date, setDate] = useState(new Date());
  const [finalHomeScore, setFinalHomeScore] = useState(0);
  const [finalAwayScore, setFinalAwayScore] = useState(0);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setDate(new Date(game?.date));
    setFinalHomeScore(game?.homeScore);
    setFinalAwayScore(game?.awayScore);
  }, [game?.date, game?.homeScore, game?.awayScore]);

  const showDatepicker = () => {
    setShow(true);
  };

  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate || date;
    setShow(false);
    setDate(currentDate);
  };

  const handleSave = () => {
    endGame(date, finalHomeScore, finalAwayScore);
  };

  return (
    <Modal
      visible={isEndGameModalVisible}
      transparent={true}
      animationType="slide"
      // onRequestClose={closeEndGameModal(date, finalHomeScore, finalAwayScore)}
    >
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>End Game</Text>
          <Text>Game Date:</Text>
          <TouchableOpacity onPress={showDatepicker}>
            <Text style={styles.dateText}>{date.toLocaleDateString()}</Text>
          </TouchableOpacity>
          {show && (
            <DateTimePicker
              testID="dateTimePicker"
              value={date}
              mode="date"
              is24Hour={true}
              display="default"
              onChange={onChange}
            />
          )}
          <Text style={styles.finalScoreText}>Final Score:</Text>
          <Text>Away Team: {game?.awayTeam?.name}</Text>
          <TextInput
            style={styles.input}
            keyboardType="numeric"
            value={String(finalAwayScore)}
            onChangeText={(value) => setFinalAwayScore(Number(value))}
            placeholder="Enter final away score"
          />
          <Text>Home Team: {game?.homeTeam?.name}</Text>
          <TextInput
            style={styles.input}
            keyboardType="numeric"
            value={String(finalHomeScore)}
            onChangeText={(value) => setFinalHomeScore(Number(value))}
            placeholder="Enter final home score"
          />
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
            <Button title = "Save Game"  onPress={handleSave}/>
            </View>
            <View style={styles.button}>
            <Button title = "Cancel"
              onPress={closeEndGameModal}
              color="red"
            />
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    width: 300,
    padding: 20,
    backgroundColor: "white",
    borderRadius: 10,
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
    textAlign: "center",
  },
  dateText: {
    fontSize: 18,
    color: "blue",
    marginBottom: 20,
  },
  finalScoreText: {
    fontSize: 18,
    marginBottom: 20,
  },
  input: {
    height: 40,
    borderColor: "gray",
    borderWidth: 1,
    paddingHorizontal: 10,
    marginBottom: 20,
  },
  // endGameButton: {
  //   backgroundColor: "green",
  //   paddingVertical: 10,
  //   paddingHorizontal: 20,
  //   borderRadius: 5,
  //   marginVertical: 5,
  //   width: "45%",
  // },
  // cancelButton: {
  //   backgroundColor: "red",
  //   paddingVertical: 10,
  //   paddingHorizontal: 20,
  //   borderRadius: 5,
  //   marginVertical: 5,
  //   width: "45%",
  // },
  // buttonText: {
  //   color: "white",
  //   fontWeight: "bold",
  //   textAlign: "center",
  // },
  buttonContainer: {
    marginTop: 20,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  button: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    width: "60%",
  },
});

export default EndGameModal;
