// components/AtBatModal.js
import React from "react";
import { View, Modal, Button, StyleSheet } from "react-native";
import { Picker } from "@react-native-picker/picker";
import styles from "./styles";

const AtBatModal = ({
  isVisible,
  onClose,
  onSave,
  atBatResult,
  setAtBatResult,
  ballLocation,
  setBallLocation,
}) => {
  const atBatOptions = [
    "Single",
    "Double",
    "Triple",
    "Home Run",
    "Ground Out",
    "Pop Out",
    "Line Out",
    "Fly Out",
    "Strike Out",
    "Sacrifice Fly",
    "Fielder's Choice",
  ];

  const locationOptions = [
    "P",
    "C",
    "1B",
    "2B",
    "3B",
    "SS",
    "LF",
    "CF",
    "RF",
    "LR",
    "RR",
    "NA",
  ];

  return (
    <Modal
      visible={isVisible}
      transparent={true}
      animationType="slide"
      onRequestClose={onClose}
    >
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <View style={styles.picker}>
            <Picker
              selectedValue={atBatResult}
              onValueChange={(itemValue) => setAtBatResult(itemValue)}
            >
              <Picker.Item label="Select an option..." value={null} />
              {atBatOptions.map((option, index) => (
                <Picker.Item key={index} label={option} value={option} />
              ))}
            </Picker>
          </View>
          <View style={styles.picker}>
            <Picker
              selectedValue={ballLocation}
              onValueChange={(itemValue) => setBallLocation(itemValue)}
            >
              <Picker.Item label="Select an option..." value={null} />
              {locationOptions.map((option, index) => (
                <Picker.Item key={index} label={option} value={option} />
              ))}
            </Picker>
          </View>
          <View style={styles.buttonRow}>
            <View style={styles.buttonContainer}>
              <View style={styles.button}>
                <Button title="Save" onPress={onSave} color="green" />
              </View>
            </View>
            <View style={styles.buttonContainer}>
              <View style={styles.button}>
                <Button title="Cancel" onPress={onClose} color="red" />
              </View>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default AtBatModal;
