import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Button,
  FlatList,
  TouchableOpacity,
  Alert,
  ImageBackground,
} from "react-native";
import axios from "axios";
import { BASE_URL } from "@env";
import { useFocusEffect } from "@react-navigation/native";
import showAlert from "../utils/showAlert";
import styles from "./styles";

const GamesScreen = ({ navigation }) => {
  const [games, setGames] = useState([]);

  useFocusEffect(
    React.useCallback(() => {
      fetchGames();
    }, [])
  );

  const fetchGames = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/game`);
      setGames(response.data);
    } catch (error) {
      console.error("Error fetching games:", error);
      showAlert("Error", "There was an error fetching the games.");
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <ImageBackground
      source={require("../assets/ScorekeepAppIcon.png")}
      style={styles.backgroundImage}
    >
      <View style={styles.overlay}>
        <FlatList
          data={games}
          keyExtractor={(item) => item._id}
          renderItem={({ item }) => (
            <View style={styles.list}>
              <TouchableOpacity
                onPress={() =>
                  navigation.navigate("GameDetails", { gameId: item._id })
                }
              >
                <Text style={styles.h6}>
                  {`${formatDate(item.date)} - ${
                    item.awayTeam && item.awayTeam.name
                      ? item.awayTeam.name
                      : "Unknown Away Team"
                  }(${item?.awayScore || 0}) vs ${
                    item.homeTeam && item.homeTeam.name
                      ? item.homeTeam.name
                      : "Unknown Home Team"
                  }(${item?.homeScore || 0})`}
                  {item?.isFinished && " -- Final"}
                </Text>
              </TouchableOpacity>
            </View>
          )}
        />
        <View style={styles.buttonRow}>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button
                title="Add New Game"
                onPress={() => navigation.navigate("AddNewGame")}
              />
            </View>
          </View>
        </View>
      </View>
    </ImageBackground>
  );
};

export default GamesScreen;
