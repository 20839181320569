import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TextInput,
  Button,
  StyleSheet,
  Alert,
  TouchableOpacity,
  Platform,
  ScrollView,
} from "react-native";
import DateTimePicker from "@react-native-community/datetimepicker";
import axios from "axios";
import { BASE_URL } from "@env";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import { Picker } from "@react-native-picker/picker";
import showAlert from "../utils/showAlert";
import styles from "./styles";

const AddNewGame = () => {
  const [homeTeam, setHomeTeam] = useState("");
  const [awayTeam, setAwayTeam] = useState("");
  const [teams, setTeams] = useState([]);
  const [umpire, setUmpire] = useState("");
  const [location, setLocation] = useState("");
  const [date, setDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [homeScore, setHomeScore] = useState("");
  const [awayScore, setAwayScore] = useState("");
  const [season, setSeason] = useState(new Date().getFullYear().toString());
  const [type, setType] = useState("regular season"); // Default to "regular season"
  const [homeLineup, setHomeLineup] = useState([]);
  const [awayLineup, setAwayLineup] = useState([]);
  const navigation = useNavigation();

  useFocusEffect(
    React.useCallback(() => {
      fetchTeams();
    }, [])
  );

  const fetchTeams = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/team`);
      setTeams(response.data);
    } catch (error) {
      console.error("Error fetching teams:", error);
      showAlert("Error", "There was an error fetching the teams.");
    }
  };

  const onChange = (event, selectedDate) => {
    const currentDate = selectedDate || date;
    setShow(Platform.OS === "ios");
    setDate(currentDate);
  };

  const showDatepicker = () => {
    setShow(true);
  };

  const saveGame = async () => {
    if (!homeTeam || !awayTeam || !season) {
      showAlert("Validation Error", "Please fill in all required fields.");
      return;
    }

    try {
      const newGame = {
        homeTeam,
        awayTeam,
        umpire,
        location,
        date,
        homeScore: homeScore ? parseInt(homeScore, 10) : 0, // Use 0 if homeScore is empty
        awayScore: awayScore ? parseInt(awayScore, 10) : 0, // Use 0 if awayScore is empty
        season: parseInt(season, 10),
        type,
        homeLineup,
        awayLineup,
        homeInnings: [
          {
            number: 1,
            half: "top",
            runs: 0,
            outs: 0,
            homeAtBats: [],
            awayAtBats: [],
            isMercy: false,
            firstBase: null,
            secondBase: null,
            thirdBase: null,
          },
        ],
        awayInnings: [
          {
            number: 1,
            half: "bottom",
            runs: 0,
            outs: 0,
            homeAtBats: [],
            awayAtBats: [],
            isMercy: false,
            firstBase: null,
            secondBase: null,
            thirdBase: null,
          },
        ],
        isArchived: false, // Add this line
      };

      const response = await axios.post(`${BASE_URL}/api/game/create`, newGame);
      const createdGame = response.data;
      showAlert("Game Created", "The game has been created successfully.", [
        {
          text: "OK",
          onPress: () =>
            navigation.navigate("GameDetails", { gameId: createdGame._id }),
        },
      ]);
    } catch (error) {
      console.error("Error creating game:", error);
      showAlert("Error", "There was an error creating the game.");
    }
  };

  return (
    <View style={styles.container}>
      <ScrollView vertical>
        <Text>Date (required):</Text>
        <TouchableOpacity onPress={showDatepicker}>
          <Text style={styles.dateText}>{date.toLocaleDateString()}</Text>
        </TouchableOpacity>
        {show && (
          <DateTimePicker
            testID="dateTimePicker"
            value={date}
            mode="date"
            is24Hour={true}
            display="default"
            onChange={onChange}
          />
        )}
        <Text>Home Team (required):</Text>
        <View style={styles.pickerContainer}>
          <View style={styles.pickerRow}>
            <Picker
              selectedValue={homeTeam}
              onValueChange={(itemValue, itemIndex) => setHomeTeam(itemValue)}
              style={styles.teamPicker}
            >
              <Picker.Item label="Select Home Team" value="" />
              {teams.map((team) => (
                <Picker.Item
                  key={team._id}
                  label={team.name}
                  value={team._id}
                />
              ))}
            </Picker>
            <TouchableOpacity
              onPress={() =>
                navigation.navigate("AddNewTeam", {
                  previousScreen: "AddNewGame",
                })
              }
            >
              <Text style={styles.addButton}>+</Text>
            </TouchableOpacity>
          </View>
        </View>

        <Text>Away Team (required):</Text>
        <View style={styles.pickerContainer}>
          <View style={styles.pickerRow}>
            <Picker
              selectedValue={awayTeam}
              onValueChange={(itemValue, itemIndex) => setAwayTeam(itemValue)}
              style={styles.teamPicker}
            >
              <Picker.Item label="Select Away Team" value="" />
              {teams.map((team) => (
                <Picker.Item
                  key={team._id}
                  label={team.name}
                  value={team._id}
                />
              ))}
            </Picker>
            <TouchableOpacity
              onPress={() =>
                navigation.navigate("AddNewTeam", {
                  previousScreen: "AddNewGame",
                })
              }
            >
              <Text style={styles.addButton}>+</Text>
            </TouchableOpacity>
          </View>
        </View>

        <Text>Umpire:</Text>
        <TextInput
          style={styles.input}
          value={umpire}
          onChangeText={setUmpire}
          placeholder="Enter Umpire"
        />
        <Text>Location:</Text>
        <TextInput
          style={styles.input}
          value={location}
          onChangeText={setLocation}
          placeholder="Enter Location"
        />
        <Text>Season (required):</Text>
        <TextInput
          style={styles.input}
          value={season}
          onChangeText={setSeason}
          placeholder="Enter Season"
          keyboardType="numeric"
        />
        <Text>Type of Game:</Text>
        <View style={styles.pickerContainer}>
          <Picker
            selectedValue={type}
            onValueChange={(itemValue, itemIndex) => setType(itemValue)}
            style={styles.picker}
          >
            <Picker.Item label="Select Type" value="" />
            <Picker.Item label="Exhibition" value="exhibition" />
            <Picker.Item label="Regular Season" value="regular season" />
            <Picker.Item label="Playoff" value="playoff" />
          </Picker>
        </View>
      </ScrollView>
      <View style={styles.buttonRow}>
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <Button title="Save Game" onPress={saveGame} color="green" />
          </View>
        </View>
      </View>
    </View>
  );
};

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     justifyContent: "center",
//     padding: 20,
//   },
//   input: {
//     height: 40,
//     borderColor: "gray",
//     borderWidth: 1,
//     marginBottom: 12,
//     paddingHorizontal: 8,
//   },
//   dateText: {
//     fontSize: 18,
//     marginBottom: 12,
//     color: "blue",
//   },
//   pickerContainer: {
//     borderColor: "gray",
//     borderWidth: 1,
//     borderRadius: 5,
//     marginBottom: 12,
//   },
//   picker: {
//     height: 50,
//     width: "90%",
//   },
//   pickerRow: {
//     flexDirection: "row",
//     alignItems: "center",
//   },
//   addButton: {
//     fontSize: 24,
//     marginLeft: 10,
//     marginRight: 20,
//     color: "blue",
//   },
// });

export default AddNewGame;
