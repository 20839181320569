import React, { useState } from "react";
import { View, Modal, Button, Alert } from "react-native";
import { Picker } from "@react-native-picker/picker";
import showAlert from "../../utils/showAlert";
import styles from "./styles";

const BaseModal = ({
  isBaseModalVisible,
  closeBaseModal,
  currentAtBats,
  assignPlayerToBase,
  clearBase,
}) => {
  const [selectedAtBat, setSelectedAtBat] = useState(null);

  const handleAssignPlayer = () => {
    const selectedAtBatData = currentAtBats.find(
      (atBat) => atBat._id === selectedAtBat
    );
    if (
      selectedAtBatData &&
      (selectedAtBatData.outcome === "Out" ||
        selectedAtBatData.outcome === "Score")
    ) {
      showAlert(
        "Confirm Change",
        "This player's previous outcome was either 'Out' or 'Score'. Are you sure you want to change it?",
        [
          { text: "Cancel", onPress: () => {}, style: "cancel" },
          { text: "OK", onPress: () => assignPlayerToBase(selectedAtBat) },
        ]
      );
    } else {
      assignPlayerToBase(selectedAtBat);
    }
  };

  return (
    <Modal
      visible={isBaseModalVisible}
      transparent={true}
      animationType="slide"
      onRequestClose={closeBaseModal}
    >
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <Picker
            selectedValue={selectedAtBat}
            onValueChange={(itemValue) => setSelectedAtBat(itemValue)}
          >
            <Picker.Item label="Select an player..." value={null} />
            {currentAtBats?.map((atBat) => (
              <Picker.Item
                key={atBat._id}
                label={`#${atBat.player.number} - ${atBat.player.firstName} ${atBat.player.lastName}`}
                value={atBat._id}
              />
            ))}
          </Picker>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button
                title="Assign Player to Base"
                onPress={handleAssignPlayer}
                color="blue"
              />
            </View>
          </View>
          <View style={styles.buttonRow}>
            <View style={styles.buttonContainer}>
              <View style={styles.button}>
                <Button title="Clear Base" onPress={clearBase} color="green" />
              </View>
            </View>
            <View style={styles.buttonContainer}>
              <View style={styles.button}>
                <Button title="Cancel" onPress={closeBaseModal} color="red" />
              </View>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default BaseModal;
