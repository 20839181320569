// screens/PlayerDetailsScreen.js
import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, FlatList, Button, Alert } from "react-native";
import axios from "axios";
import { BASE_URL } from "@env";
import {
  useNavigation,
  useRoute,
  useFocusEffect,
} from "@react-navigation/native";
import showAlert from "../utils/showAlert";
import styles from "./styles";

const PlayerDetailsScreen = () => {
  const route = useRoute();
  const { playerId } = route.params;
  const [player, setPlayer] = useState(null);
  const navigation = useNavigation();

  const fetchPlayerDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/player/${playerId}`);
      const playerData = response.data;
      if (playerData.currentTeam && playerData.currentTeam.isArchived) {
        playerData.currentTeam = null; // Set to null if current team is archived
      }
      playerData.pastTeams = playerData.pastTeams.filter(
        (team) => !team.isArchived
      ); // Filter out archived past teams
      setPlayer(playerData);
    } catch (error) {
      console.error("Error fetching player details:", error);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      fetchPlayerDetails();
    }, [playerId])
  );

  const confirmDeletePlayer = () => {
    showAlert(
      "Delete Player",
      "Are you sure you want to delete this player?",
      [
        { text: "Cancel", style: "cancel" },
        { text: "OK", onPress: deletePlayer },
      ],
      { cancelable: false }
    );
  };

  const deletePlayer = async () => {
    try {
      await axios.delete(`${BASE_URL}/api/player/${playerId}`);
      showAlert("Player Deleted", "The player has been deleted successfully.", [
        { text: "OK", onPress: () => navigation.goBack() },
      ]);
    } catch (error) {
      console.error("Error deleting player:", error);
      showAlert("Error", "There was an error deleting the player.");
    }
  };

  const handleViewStats = () => {
    navigation.navigate("PlayerStats", { playerId: player._id });
  };

  const handleViewSprayChart = () => {
    navigation.navigate("SprayChart", { playerId: player._id });
  };

  if (!player) {
    return (
      <View style={styles.container}>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.buttonRow}>
        <View style={styles.buttonContainer}>
          <Button title="See Stats" onPress={handleViewStats} />
        </View>
        <View style={styles.buttonContainer}>
          <Button
            title="Spray Chart"
            onPress={handleViewSprayChart}
            color="green"
          />
        </View>
      </View>
      <View style={styles.list}>
        <View style={styles.twoColumns}>
          <Text style={styles.listHeader}>Name:</Text>
          <Text style={styles.listItem}>
            {player.firstName} {player.lastName}
          </Text>
        </View>
        <View style={styles.twoColumns}>
          <Text style={styles.listHeader}>Number:</Text>
          <Text style={styles.listItem}>{player.number || "N/A"}</Text>
        </View>
        <View style={styles.twoColumns}>
          <Text style={styles.listHeader}>Gender:</Text>
          <Text style={styles.listItem}>{player.gender}</Text>
        </View>
        <View style={styles.twoColumns}>
          <Text style={styles.listHeader}>Current Team:</Text>
          <Text style={styles.listItem}>
            {player.currentTeam
              ? player.currentTeam.season + " " + player.currentTeam.name
              : "N/A"}
          </Text>
        </View>
        <View style={styles.twoColumns}>
          <Text style={styles.listHeader}>Past Teams:</Text>
          {player.pastTeams.length > 0 ? (
            <FlatList
              data={player.pastTeams}
              keyExtractor={(team) => team._id}
              renderItem={({ item }) => (
                <Text style={styles.listItem}>
                  - {item.season + " " + item.name}
                </Text>
              )}
            />
          ) : (
            <Text style={styles.listItem}>No past teams</Text>
          )}
        </View>
      </View>
      <View style={styles.buttonRow}>
        <View style={styles.buttonContainer}>
          <Button
            title="Edit Player"
            onPress={() => navigation.navigate("EditPlayer", { playerId })}
            style={styles.button}
          />
        </View>
        <View style={styles.buttonContainer}>
          <Button
            title="Delete Player"
            onPress={confirmDeletePlayer}
            color="red"
            style={styles.button}
          />
        </View>
      </View>
    </View>
  );
};

export default PlayerDetailsScreen;
