// screens/EditPlayerScreen.js
import React, { useState, useEffect } from "react";
import { View, Text, TextInput, Button, StyleSheet, Alert } from "react-native";
import axios from "axios";
import { BASE_URL } from "@env";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Picker } from "@react-native-picker/picker";
import showAlert from "../utils/showAlert";
import styles from "./styles";

const EditPlayerScreen = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { playerId } = route.params;
  const [player, setPlayer] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("M");
  const [jerseyNumber, setJerseyNumber] = useState("");
  const [currentTeam, setCurrentTeam] = useState("");
  const [originalTeam, setOriginalTeam] = useState(""); // Track the original team
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    fetchTeams();
    fetchPlayerDetails();
  }, []);

  const fetchTeams = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/team`);
      const activeTeams = response.data.filter((team) => !team.isArchived); // Add this line
      setTeams(activeTeams); // Update this line
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };

  const fetchPlayerDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/player/${playerId}`);
      const playerData = response.data;
      setPlayer(playerData);
      setFirstName(playerData.firstName);
      setLastName(playerData.lastName);
      setGender(playerData.gender);
      setJerseyNumber(playerData.number ? playerData.number.toString() : "");
      setCurrentTeam(
        playerData.currentTeam && !playerData.currentTeam.isArchived
          ? playerData.currentTeam._id
          : ""
      ); // Update this line
      setOriginalTeam(
        playerData.currentTeam && !playerData.currentTeam.isArchived
          ? playerData.currentTeam._id
          : ""
      ); // Update this line
    } catch (error) {
      console.error("Error fetching player details:", error);
    }
  };

  const savePlayer = async () => {
    if (!firstName || !gender || !currentTeam) {
      showAlert("Validation Error", "Please fill in all required fields.");
      return;
    }

    try {
      // Check if the team has changed
      if (originalTeam && originalTeam !== currentTeam) {
        showAlert(
          "Team Changed",
          "Do you want to add the previous team to the past teams?",
          [
            {
              text: "No",
              onPress: () => updatePlayer(false),
              style: "cancel",
            },
            { text: "Yes", onPress: () => updatePlayer(true) },
          ],
          { cancelable: false }
        );
      } else {
        updatePlayer(false);
      }
    } catch (error) {
      console.error("Error updating player:", error);
      showAlert("Error", "There was an error updating the player.");
    }
  };

  const updatePlayer = async (addToPastTeams) => {
    try {
      // Update player details
      const updatedPlayer = {
        firstName,
        lastName,
        gender,
        number: jerseyNumber ? parseInt(jerseyNumber, 10) : undefined,
        currentTeam,
        pastTeams: addToPastTeams
          ? [...player.pastTeams, originalTeam]
          : player.pastTeams,
      };
      await axios.put(`${BASE_URL}/api/player/${playerId}`, updatedPlayer);

      showAlert("Player Updated", "The player has been updated successfully.", [
        { text: "OK", onPress: () => navigation.goBack() },
      ]);
    } catch (error) {
      console.error("Error updating player:", error);
      showAlert("Error", "There was an error updating the player.");
    }
  };

  if (!player) {
    return (
      <View style={styles.container}>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View styls={styles.list}>
        <Text>First Name (required):</Text>
        <TextInput
          style={styles.input}
          value={firstName}
          onChangeText={setFirstName}
          placeholder="Enter First Name"
        />
        <Text>Last Name:</Text>
        <TextInput
          style={styles.input}
          value={lastName}
          onChangeText={setLastName}
          placeholder="Enter Last Name"
        />
        <Text>Gender (required):</Text>
        <View style={styles.pickerContainer}>
          <Picker
            selectedValue={gender}
            onValueChange={(itemValue, itemIndex) => setGender(itemValue)}
            style={styles.picker}
          >
            <Picker.Item label="Male" value="M" />
            <Picker.Item label="Female" value="F" />
            <Picker.Item label="Not Applicable" value="NA" />
          </Picker>
        </View>
        <Text>Jersey Number:</Text>
        <TextInput
          style={styles.input}
          value={jerseyNumber}
          onChangeText={setJerseyNumber}
          placeholder="Enter Jersey Number"
          keyboardType="numeric"
        />
        <Text>Current Team (required):</Text>
        <View style={styles.pickerContainer}>
          <Picker
            selectedValue={currentTeam}
            onValueChange={(itemValue, itemIndex) => setCurrentTeam(itemValue)}
            style={styles.picker}
          >
            {teams.map((team) => (
              <Picker.Item
                key={team._id}
                label={team.season + " " + team.name}
                value={team._id}
              />
            ))}
          </Picker>
        </View>
      </View>
      <View style={styles.buttonRow}>
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <Button title="Save Player" onPress={savePlayer} color="green" />
          </View>
        </View>
      </View>
    </View>
  );
};

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
//     justifyContent: "center",
//     padding: 20,
//   },
//   input: {
//     height: 40,
//     borderColor: "gray",
//     borderWidth: 1,
//     marginBottom: 12,
//     paddingHorizontal: 8,
//   },
//   pickerContainer: {
//     borderColor: "gray",
//     borderWidth: 1,
//     borderRadius: 5,
//     marginBottom: 12,
//   },
//   picker: {
//     height: 50,
//     width: "100%",
//   },
// });

export default EditPlayerScreen;
