// AtBatsTable.js
import React from "react";
import { View, Text, StyleSheet, TouchableOpacity,ScrollView } from "react-native";
import { useNavigation } from "@react-navigation/native";

const AtBatsTable = ({ atBats }) => {
  const navigation = useNavigation();
  // Sort the atBats by the "order" property
  // const sortedAtBats = [...atBats].sort((a, b) => a.order - b.order);

  return (
    <ScrollView style={styles.container}>

    <View style={styles.atBatsTable}>
      <Text style={styles.tableTitle}>At Bats This Inning:</Text>
      <View style={styles.tableHeader}>
        <Text style={styles.tableHeaderCell}>Player #</Text>
        <Text style={styles.tableHeaderCell}>Result</Text>
        <Text style={styles.tableHeaderCell}>Location</Text>
        <Text style={styles.tableHeaderCell}>Out</Text>
        <Text style={styles.tableHeaderCell}>Score</Text>
      </View>
      {atBats.map((atBat) => (
        <TouchableOpacity key={atBat._id} style={styles.tableRow} onPress={() => navigation.navigate('AtBatEdit', { atBatId: atBat._id })}>
          <Text style={styles.tableCell}>{atBat.player.number}</Text>
          <Text style={styles.tableCell}>{atBat.result}</Text>
          <Text style={styles.tableCell}>{atBat.location}</Text>
          <Text style={styles.tableCell}>{atBat.outcome === "Out" ? "Yes" : "No"}</Text>
          <Text style={styles.tableCell}>{atBat.outcome === "Score" ? "Yes" : "No"}</Text>
        </TouchableOpacity>
      ))}
    </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  atBatsTable: {
    marginTop: 20,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
  },
  tableTitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 10,
  },
  tableHeader: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    paddingBottom: 5,
    marginBottom: 5,
  },
  tableHeaderCell: {
    flex: 1,
    fontWeight: "bold",
  },
  tableRow: {
    flexDirection: "row",
    marginBottom: 5,
  },
  tableCell: {
    flex: 1,
  },
});

export default AtBatsTable;
