import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    justifyContent: "center",
  },
  twoColumns: {
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 2,
  },
  column: {
    flex: 1,
    marginRight: 2,
    marginBottom: 2,
  },
  listContainer: {
    flex: 1,
    marginTop: 5,
  },
  list: {
    flex: 1,
    padding: 8,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    backgroundColor: "#fff",
    marginVertical: 1,
  },
  listHeader: {
    flex: 1,
    padding: 8,
    backgroundColor: "#fff",
    marginVertical: 1,
    fontSize: 16,
  },
  listItem: {
    flex: 2,
    padding: 8,
    // borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    backgroundColor: "#fff",
    marginVertical: 1,
    fontSize: 16,
    fontWeight: "bold",
  },
  playerContainer: {
    backgroundColor: "#fff",
    paddingHorizontal: 2,
    marginBottom: 2,
    borderRadius: 8,
  },
  h3: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
  },
  h4: {
    fontSize: 18,
    fontWeight: "bold",
    marginTop: 10,
  },
  h5: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 10,
  },
  h6: {
    fontSize: 16,
  },
  buttonContainer: {
    marginTop: 0,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-around",
    minWidth: "100%",
  },
  button: {
    paddingVertical: 5,
    borderRadius: 5,
    minWidth: "45%",
  },
  scoreButton: {
    paddingVertical: 5,
    paddingHorizontal: 20,
    borderRadius: 5,
    width: "110%",
  },
  buttonRow: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    marginTop: 10,
    marginBottom: 10,
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  playerInfo: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  playerIndex: {
    width: 20,
    textAlign: "right",
    marginRight: 5,
  },
  playerNumber: {
    width: 40,
    textAlign: "right",
    marginRight: 5,
  },
  playerName: {
    flex: 1,
    marginRight: 5,
  },
  playerGender: {
    width: 40,
    textAlign: "left",
  },
  playerActions: {
    flexDirection: "row",
    alignItems: "center",
  },
  pickerRow: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
    paddingRight: 15,
    borderWidth: 1,
  },
  picker: {
    height: 50,
    width: "100%",
  },
  pickerContainer: {
    borderColor: "gray",
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 12,
  },
  lineupPlayerContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 10,
    borderWidth: 1,
    borderRadius: 5,
    backgroundColor: "#eee",
    marginTop: 5,
  },
  addButtonContainer: {
    marginLeft: 10,
  },
  addButton: {
    fontSize: 18,
    color: "blue",
  },
  moveButton: {
    fontSize: 18,
    color: "green",
    marginHorizontal: 10,
  },
  removeButton: {
    fontSize: 16,
    color: "red",
  },
  input: {
    height: 50,
    borderColor: "gray",
    borderWidth: 1,
    marginBottom: 12,
    paddingHorizontal: 8,
    borderRadius: 5,
  },
  dateText: {
    fontSize: 18,
    marginBottom: 12,
    color: "blue",
  },
  pickerRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  teamPicker: {
    height: 50,
    width: "85%",
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
  },
  overlay: {
    flex: 1,
    backgroundColor: "rgba(225, 225, 225, .95)", // Black color with 50% opacity
    justifyContent: "center",
    width: "100%",
    alignItems: "left",
    padding: 20,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  section: {
    marginBottom: 20,
  },
  sprayChartTable: {
    flexDirection: "column",
    marginBottom: 20,
  },
  sprayChartTableHeader: {
    flexDirection: "row",
    backgroundColor: "#eee",
  },
  sprayChartTableHeaderCell: {
    flex: 1,
    padding: 8,
    fontWeight: "bold",
    textAlign: "center",
  },
  sprayChartTableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
  },
  sprayChartTableCell: {
    flex: 1,
    padding: 8,
    textAlign: "center",
  },
});
