import React, { useState, useContext } from "react";
import { View, Text, TextInput, Button, ImageBackground } from "react-native";
import AuthContext from "../AuthContext";
import showAlert from "../utils/showAlert";
import styles from "./styles";

const ChangePasswordScreen = ({ navigation }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { changePassword } = useContext(AuthContext);

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      showAlert("New password and confirmation password do not match");
      return;
    }

    try {
      await changePassword(oldPassword, newPassword);
      showAlert("Password changed successfully");
      navigation.navigate("Home");
    } catch (error) {
      console.log("Change password error:", error);
      showAlert("Failed to change password");
    }
  };

  return (
    <ImageBackground
      source={require("../assets/ScorekeepAppIcon.png")}
      style={styles.backgroundImage}
    >
      <View style={styles.overlay}>
        <Text>Old Password</Text>
        <TextInput
          style={[styles.input, { backgroundColor: "#eee" }]}
          value={oldPassword}
          onChangeText={setOldPassword}
          secureTextEntry
        />
        <Text>New Password</Text>
        <TextInput
          style={[styles.input, { backgroundColor: "#eee" }]}
          value={newPassword}
          onChangeText={setNewPassword}
          secureTextEntry
        />
        <Text>Confirm New Password</Text>
        <TextInput
          style={[styles.input, { backgroundColor: "#eee" }]}
          value={confirmPassword}
          onChangeText={setConfirmPassword}
          secureTextEntry
        />
        <View style={styles.buttonRow}>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button title="Change Password" onPress={handleChangePassword} />
            </View>
          </View>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button
                title="Cancel"
                color="red"
                onPress={() => navigation.navigate("Home")}
              />
            </View>
          </View>
        </View>
      </View>
    </ImageBackground>
  );
};

export default ChangePasswordScreen;
