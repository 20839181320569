// screens/StatsMainScreen.js
import React from 'react';
import { View, Button, ImageBackground } from 'react-native';
import styles from './styles';

const StatsMainScreen = ({ navigation }) => {
  return (
    <ImageBackground
      source={require("../../assets/ScorekeepAppIcon.png")}
      style={styles.backgroundImage}
    >
      <View style={styles.overlay}>
        <View style={styles.buttonRow}>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button
                title="Team Stats"
                onPress={() => navigation.navigate("TeamStatsMain")}
              />
            </View>
          </View>
        </View>
        <View style={styles.buttonRow}>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button
                title="Season Stats"
                onPress={() => navigation.navigate("SeasonStats")}
              />
            </View>
          </View>
        </View>
        <View style={styles.buttonRow}>
          <View style={styles.buttonContainer}>
            <View style={styles.button}>
              <Button
                title="Player Stats"
                onPress={() => navigation.navigate("PlayerStatsMain")}
              />
            </View>
          </View>
        </View>
      </View>
    </ImageBackground>
  );
};

export default StatsMainScreen;
