import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  infoBox: {
    marginBottom: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
  },
  diamond: {
    position: "relative",
    width: "50%",
    height: "50%",
    margin: "auto", // Center the diamond
  },
  base: {
    position: "absolute",
    width: 45,
    height: 45,
    backgroundColor: "lightgrey",
    borderWidth: 1,
    borderColor: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: [{ rotate: "45deg" }],
  },
  baseText: {
    transform: [{ rotate: "-45deg" }],
  },
  firstBase: {
    top: "50%",
    right: "50%",
    transform: [{ translateX: 70 }, { translateY: -25 }, { rotate: "45deg" }],
  },
  secondBase: {
    top: "50%",
    right:"50%",
    transform: [{ translateX: 25 }, { translateY: -70 }, { rotate: "45deg" }],
  },
  thirdBase: {
    top: "50%",
    right: "50%",
    transform: [{ translateX: -20 }, { translateY: -25 }, { rotate: "45deg" }],
  },
  homeBase: {
    top: "50%",
    right:"50%",
    transform: [{ translateX: 25 }, { translateY: 20 }, { rotate: "45deg" }],
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  input: {
    height: 40,
    borderColor: "gray",
    borderWidth: 1,
    flex: 1,
    paddingHorizontal: 10,
    marginBottom: 10,
  },
  incrementDecrementContainer: {
    flexDirection: "column",
    alignItems: "center",
    marginLeft: 10,
  },
  incrementDecrementButton: {
    width: 30,
    height: 30,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "gray",
    marginVertical: 2,
  },
  incrementDecrementText: {
    fontSize: 20,
  },
  inningRow: {
    flexDirection: "row",
  },
  table: {
    flexDirection: "column",
  },
  row: {
    flexDirection: "row",
  },
  cell: {
    width: 50,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#ccc",
    textAlign: "center",
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  modalContent: {
    width: 300,
    padding: 20,
    margin: 20,
    backgroundColor: "white",
    borderRadius: 10,
  },
  picker:{
    marginBottom: 5,
    flex:1,
    marginRight: 10,
  },
  modalTitle: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    height: '25%', // Top quarter of the screen
  },
  box: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'black',
  },
  buttonContainer: {
    // flex:1,
    marginTop: 0,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-around",
    // width: "100%",
  },
  button: {
    paddingVertical: 5,
    // paddingHorizontal: 30,
    borderRadius: 5,
    minWidth: "45%",
    // width: "60%",
  },
  buttonRow: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
    marginTop: 10,
    marginBottom: 10,
  },
  h5: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
  },
  atBatRow:{
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  atBatColumn:{
    flexDirection: "column",
    flex:1,
  },
  h6: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  onDeckText: {
    fontSize: 14,
    marginBottom: 5,
  },
});
