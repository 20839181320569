import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  Button,
} from "react-native";
import axios from "axios";
import { Picker } from "@react-native-picker/picker";
import { BASE_URL } from "@env";
import styles from "./styles";

const PlayerStatsScreen = ({ route, navigation }) => {
  const { playerId, season, team } = route.params;
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [seasons, setSeasons] = useState([]);
  const [teams, setTeams] = useState([]);
  const [players, setPlayers] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState(season);
  const [selectedTeam, setSelectedTeam] = useState(team);
  const [selectedPlayer, setSelectedPlayer] = useState(playerId);
  const [selectedStatType, setSelectedStatType] = useState("total");
  const [playerDetails, setPlayerDetails] = useState(null);

  useEffect(() => {
    const fetchSeasons = async () => {
      try {
        const teamsResponse = await axios.get(`${BASE_URL}/api/team`);
        const uniqueSeasons = [
          ...new Set(teamsResponse.data.map((team) => team.season)),
        ];
        setSeasons(uniqueSeasons);
      } catch (error) {
        console.error("Error fetching seasons:", error);
      }
    };

    fetchSeasons();
  }, []);

  useEffect(() => {
    if (selectedSeason) {
      const fetchTeams = async () => {
        try {
          const teamsResponse = await axios.get(`${BASE_URL}/api/team`);
          const filteredTeams = teamsResponse.data.filter(
            (team) => team.season === parseInt(selectedSeason)
          );
          setTeams(filteredTeams);
        } catch (error) {
          console.error("Error fetching teams:", error);
        }
      };

      fetchTeams();
    }
  }, [selectedSeason]);

  useEffect(() => {
    if (selectedTeam) {
      const fetchPlayers = async () => {
        try {
          const teamResponse = await axios.get(
            `${BASE_URL}/api/team/${selectedTeam}`
          );
          setPlayers(teamResponse.data.players);
        } catch (error) {
          console.error("Error fetching players:", error);
        }
      };

      fetchPlayers();
    }
  }, [selectedTeam]);

  useEffect(() => {
    if (selectedPlayer) {
      const fetchPlayerStats = async () => {
        try {
          const response = await axios.get(
            `${BASE_URL}/api/player/${selectedPlayer}/stats`
          );
          setStats(response.data);
          fetchPlayerDetails(selectedPlayer);
        } catch (error) {
          console.error("Error fetching player stats:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchPlayerStats();
    }
  }, [selectedPlayer]);

  const fetchPlayerDetails = async (playerId) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/player/${playerId}`);
      setPlayerDetails(response.data);
    } catch (error) {
      console.error("Error fetching player details:", error);
    }
  };

  if (loading) {
    return <ActivityIndicator size="large" color="#0000ff" />;
  }

  if (!stats) {
    return (
      <View style={styles.container}>
        <Text>No stats available for this player.</Text>
      </View>
    );
  }

  const renderStats = (data) => {
    if (!data) {
      return null;
    }

    return (
      <View style={styles.section}>
        <Text>Total At-Bats: {data.atBats}</Text>
        <Text>Singles (1B): {data.hits.Single}</Text>
        <Text>Doubles (2B): {data.hits.Double}</Text>
        <Text>Triples (3B): {data.hits.Triple}</Text>
        <Text>Home Runs (HR): {data.hits["Home Run"]}</Text>
        <Text>Fly Outs (FO): {data.outs["Fly Out"]}</Text>
        <Text>Ground Outs (GO): {data.outs["Ground Out"]}</Text>
        <Text>Pop Outs (PO): {data.outs["Pop Out"]}</Text>
        <Text>Line Outs (LO): {data.outs["Line Out"]}</Text>
        <Text>Strike Outs (SO): {data.outs["Strike Out"]}</Text>
        <Text>Fielder's Choice Outs (FC): {data.outs["Fielder's Choice"]}</Text>
        <Text>Sacrifice Flies (SF): {data.sacrifice["Sacrifice Fly"]}</Text>
        <Text>Batting Average (AVG): {data.avg.toFixed(3)}</Text>
        <Text>On-Base Percentage (OBP): {data.obp.toFixed(3)}</Text>
        <Text>Slugging Percentage (SLG): {data.slg.toFixed(3)}</Text>
        <Text>On-Base Plus Slugging (OPS): {data.ops.toFixed(3)}</Text>
        <Text>Runs Batted In (RBI): {data.RBIs}</Text>
        <Text>Runs Scored (R): {data.runs}</Text>
      </View>
    );
  };

  const getStatsByType = () => {
    switch (selectedStatType) {
      case "exhibition":
        return renderStats(stats.exhibition);
      case "regular season":
        return renderStats(stats["regular season"]);
      case "playoff":
        return renderStats(stats.playoff);
      default:
        return renderStats(stats.total);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        {playerDetails && (
          <>
            <Text
              style={styles.h4}
            >{`#${playerDetails.number} - ${playerDetails.firstName} ${playerDetails.lastName}`}</Text>
          </>
        )}
      </View>
      <View style={styles.pickerContainer}>
        <Picker
          selectedValue={selectedSeason}
          onValueChange={(itemValue) => setSelectedSeason(itemValue)}
        >
          <Picker.Item label="Select Season" value="" />
          {seasons.map((season) => (
            <Picker.Item key={season} label={`${season}`} value={season} />
          ))}
        </Picker>
      </View>

      <View style={styles.pickerContainer}>
        <Picker
          selectedValue={selectedTeam}
          onValueChange={(itemValue) => setSelectedTeam(itemValue)}
          enabled={!!selectedSeason}
        >
          <Picker.Item label="Select Team" value="" />
          {teams.map((team) => (
            <Picker.Item key={team._id} label={team.name} value={team._id} />
          ))}
        </Picker>
      </View>

      <View style={styles.pickerContainer}>
        <Picker
          selectedValue={selectedPlayer}
          onValueChange={(itemValue) => setSelectedPlayer(itemValue)}
          enabled={!!selectedTeam}
        >
          <Picker.Item label="Select Player" value="" />
          {players.map((player) => (
            <Picker.Item
              key={player._id}
              label={`${player.firstName} ${player.lastName} (#${player.number})`}
              value={player._id}
            />
          ))}
        </Picker>
      </View>

      <View style={styles.pickerContainer}>
        <Picker
          selectedValue={selectedStatType}
          onValueChange={(itemValue) => setSelectedStatType(itemValue)}
        >
          <Picker.Item label="Total" value="total" />
          <Picker.Item label="Exhibition" value="exhibition" />
          <Picker.Item label="Regular Season" value="regular season" />
          <Picker.Item label="Playoffs" value="playoff" />
        </Picker>
      </View>

      <View style={styles.buttonContainer}>
        <View style={styles.button}>
          <Button
            title="Spray Chart"
            color="green"
            onPress={() =>
              navigation.navigate("SprayChart", {
                playerId: selectedPlayer,
                season: selectedSeason,
                team: selectedTeam,
              })
            }
          />
        </View>
      </View>

      <ScrollView>{getStatsByType()}</ScrollView>
    </View>
  );
};

const localStyles = StyleSheet.create({
  pickerContainer: {
    marginVertical: 10,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 5,
    overflow: "hidden",
  },
  buttonContainer: {
    marginVertical: 10,
    alignItems: "center",
  },
  button: {
    minWidth: "80%",
  },
  section: {
    marginVertical: 10,
  },
});

export default PlayerStatsScreen;
