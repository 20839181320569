// screens/TeamDetailsScreen.js
import React, { useState, useEffect } from "react";
import { View, Text, Button, FlatList, StyleSheet, Alert } from "react-native";
import axios from "axios";
import { BASE_URL } from "@env";
import {
  useNavigation,
  useRoute,
  useFocusEffect,
} from "@react-navigation/native";
import showAlert from "../utils/showAlert";
import styles from "./styles";

const TeamDetailsScreen = () => {
  const route = useRoute();
  const { teamId } = route.params;
  const [team, setTeam] = useState(null);
  const navigation = useNavigation();

  useFocusEffect(
    React.useCallback(() => {
      fetchTeamDetails();
    }, [navigation])
  );

  const fetchTeamDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/team/${teamId}`);
      const teamData = response.data;
      const nonArchivedPlayers = teamData.players.filter(
        (player) => !player.isArchived
      ); // Filter out archived players
      teamData.players = nonArchivedPlayers;
      setTeam(teamData);
    } catch (error) {
      console.error("Error fetching team details:", error);
    }
  };

  const confirmDeleteTeam = () => {
    showAlert(
      "Delete Team",
      "Are you sure you want to delete this team?",
      [
        { text: "Cancel", style: "cancel" },
        { text: "OK", onPress: deleteTeam },
      ],
      { cancelable: false }
    );
  };

  const deleteTeam = async () => {
    try {
      await axios.delete(`${BASE_URL}/api/team/${teamId}`);
      showAlert("Team Deleted", "The team has been deleted successfully.", [
        {
          text: "OK",
          onPress: () => navigation.navigate("Teams", { refresh: true }),
        },
      ]);
    } catch (error) {
      console.error("Error deleting team:", error);
      showAlert("Error", "There was an error deleting the team.");
    }
  };

  if (!team) {
    return (
      <View style={styles.container}>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Text style={styles.h4}>Team Details:</Text>
      <View style={styles.listContainer}>
        <View style={styles.playerContainer}>
          <View style={styles.twoColumns}>
            <Text style={styles.listHeader}>Team Name:</Text>
            <Text style={styles.listItem}>{team.name}</Text>
          </View>
        </View>
      </View>
      <Text style={styles.h4}>Players:</Text>
      <View style={styles.listContainer}>
        <FlatList
          data={team.players}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => (
            <View style={styles.playerContainer}>
              <View style={styles.twoColumns}>
                <View style={styles.listHeader}>
                  <Text style={styles.h6}>#{item.number || "N/A"}</Text>
                </View>
                <View style={styles.listItem}>
                  <Text style={styles.h6}>
                    {item.firstName} {item.lastName} ({item.gender})
                  </Text>
                </View>
              </View>
            </View>
          )}
        />
      </View>
      <View style={styles.buttonRow}>
        {/* <View style={styles.buttonContainer}> */}
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <Button
              title="Add New Player"
              onPress={() =>
                navigation.navigate("AddNewPlayer", {
                  teamId: team._id,
                  // onPlayerAdded: fetchTeamDetails
                })
              }
            />
          </View>
        </View>
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <Button
              title="Edit Team"
              onPress={() =>
                navigation.navigate("EditTeam", { teamId: team._id })
              }
            />
          </View>
        </View>
      </View>
      {/* </View> */}
      <View style={styles.buttonContainer}>
        <Button
          style={styles.button}
          title="Delete Team"
          onPress={confirmDeleteTeam}
          color="red"
        />
      </View>
    </View>
  );
};

export default TeamDetailsScreen;
