import React, { useState } from "react";
import { View, Text, FlatList, Button, TouchableOpacity, ImageBackground } from "react-native";
import axios from "axios";
import { BASE_URL } from "@env";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import styles from "./styles";

const PlayersScreen = () => {
  const [players, setPlayers] = useState([]);
  const navigation = useNavigation();

  useFocusEffect(
    React.useCallback(() => {
      fetchPlayers();
    }, [])
  );

  const fetchPlayers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/player`);
      const activePlayers = response.data.filter(
        (player) => !player.isArchived
      );
      const sortedPlayers = activePlayers.sort((a, b) => {
        if (a.currentTeam && b.currentTeam) {
          if (a.currentTeam.name === b.currentTeam.name) {
            return (a.number || 0) - (b.number || 0);
          }
          return a.currentTeam.name.localeCompare(b.currentTeam.name);
        } else if (a.currentTeam) {
          return -1;
        } else if (b.currentTeam) {
          return 1;
        }
        return 0;
      });
      setPlayers(sortedPlayers);
    } catch (err) {
      console.error("Error fetching players:", err);
    }
  };

  const renderPlayer = ({ item }) => (
    <TouchableOpacity
      onPress={() =>
        navigation.navigate("PlayerDetails", { playerId: item._id })
      }
    >
      <View style={styles.playerContainer}>
        <View style={styles.twoColumns}>
          <Text style={[styles.listHeader, { padding: 4 }]}>Name:</Text>
          <Text style={[styles.listItem, { padding: 4 }]}>
            {item.firstName} {item.lastName}
          </Text>
        </View>
        <View style={styles.twoColumns}>
          <Text style={[styles.listHeader, { padding: 4 }]}>Number:</Text>
          <Text style={[styles.listItem, { padding: 4 }]}>
            {item.number || "N/A"}
          </Text>
        </View>
        <View style={styles.twoColumns}>
          <Text style={[styles.listHeader, { padding: 4 }]}>Current Team:</Text>
          <Text style={[styles.listItem, { padding: 4 }]}>
            {item.currentTeam
              ? item.currentTeam.season + " " + item.currentTeam.name
              : "N/A"}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );

  return (
    <ImageBackground
    source={require("../assets/ScorekeepAppIcon.png")}
    style={styles.backgroundImage}
  >
    <View style={styles.overlay}>
      <FlatList
        data={players}
        keyExtractor={(item) => item._id}
        renderItem={renderPlayer}
      />
      <View style={styles.buttonRow}>
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <Button
              title="Add New Player"
              onPress={() =>
                navigation.navigate("AddNewPlayer", { teamId: null })
              }
              style={styles.button}
            />
          </View>
        </View>
      </View>
    </View>
    </ImageBackground>
  );
};

export default PlayersScreen;
