import React, { useState, useCallback } from "react";
import { View, Text, Alert, Button } from "react-native";
import axios from "axios";
import { BASE_URL } from "@env";
import {
  useRoute,
  useNavigation,
  useFocusEffect,
} from "@react-navigation/native";
import styles from "./styles";
import IsHalfModal from "./IsHalfModal";
import BoxScoreModal from "./BoxScoreModal";
import EndGameModal from "./EndGameModal";
import InfoBox from "./InfoBox";
import ScoreBoard from "./ScoreBoard";
import showAlert from "../../utils/showAlert";

const BoxScore = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const { gameId } = route.params;
  const [game, setGame] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isHalfModalVisible, setHalfModalVisible] = useState(false);
  const [isEndGameModalVisible, setEndGameModalVisible] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalValue, setModalValue] = useState(0);
  const [halfValue, setHalfValue] = useState("");

  useFocusEffect(
    useCallback(() => {
      fetchGameDetails();
    }, [gameId])
  );

  const fetchGameDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/game/${gameId}`);
      const updatedGame = response.data;
      const isTopHalf = updatedGame.inningHalf === "top";
      const inningIndex = updatedGame.currentInning - 1;
      const currentOuts = isTopHalf
        ? updatedGame.awayInnings[inningIndex]?.outs
        : updatedGame.homeInnings[inningIndex]?.outs || 0;
      updatedGame.currentOuts = currentOuts;
      setGame(updatedGame);
      updateGameScore(updatedGame);
    } catch (error) {
      console.error("Error fetching game details:", error);
      showAlert(
        "Error",
        "There was an error fetching the game details. Please try again later."
      );
    }
  };

  const updateGameScore = (gameData) => {
    if (!gameData) return;

    try {
      const updatedGame = { ...gameData };
      updatedGame.awayScore = 0;
      updatedGame.homeScore = 0;
      gameData.awayInnings.forEach((inning) => {
        updatedGame.awayScore += inning?.runs || 0;
      });
      gameData.homeInnings.forEach((inning) => {
        updatedGame.homeScore += inning?.runs || 0;
      });
      setGame(updatedGame);
    } catch (error) {
      console.error("Error updating game score:", error);
    }
  };

  const openModal = (type, value) => {
    setModalType(type);
    setModalValue(value);
    setModalVisible(true);
  };

  const openHalfModal = (value) => {
    setHalfValue(value);
    setHalfModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setModalValue(0);
  };

  const closeHalfModal = () => {
    setHalfModalVisible(false);
    setHalfValue("");
  };

  const openEndGameModal = () => {
    setEndGameModalVisible(true);
  };

  const closeEndGameModal = () => {
    setEndGameModalVisible(false);
  };

  const saveChanges = async () => {
    try {
      const updatedGame = { ...game };
      if (modalType === "homeScore") {
        updatedGame.homeScore = modalValue;
      } else if (modalType === "awayScore") {
        updatedGame.awayScore = modalValue;
      } else if (modalType === "currentInning") {
        updatedGame.currentInning = modalValue < 1 ? 1 : modalValue;
      } else if (modalType === "currentOuts") {
        updatedGame.currentOuts = modalValue;
      }

      await axios.put(`${BASE_URL}/api/game/${gameId}/update`, updatedGame);
      setGame(updatedGame);
      closeModal();
    } catch (error) {
      console.error("Error saving changes:", error);
      showAlert("Error", "There was an error saving the changes.");
    }
  };

  const saveHalfChanges = async () => {
    try {
      const updatedGame = { ...game };
      updatedGame.inningHalf = halfValue;

      await axios.put(`${BASE_URL}/api/game/${gameId}/update`, updatedGame);
      setGame(updatedGame);
      closeHalfModal();
    } catch (error) {
      console.error("Error saving half changes:", error);
      showAlert("Error", "There was an error saving the changes.");
    }
  };

  const endGame = async (date, finalHomeScore, finalAwayScore) => {
    try {
      const updatedGame = { ...game };
      updatedGame.homeScore = finalHomeScore;
      updatedGame.awayScore = finalAwayScore;
      updatedGame.date = new Date(date);
      updatedGame.isFinished = true;

      await axios.put(`${BASE_URL}/api/game/${gameId}/update`, updatedGame);
      setGame(updatedGame);
      setEndGameModalVisible(false);
      navigation.navigate("Home");
    } catch (error) {
      console.error("Error ending the game:", error);
      showAlert("Error", "There was an error ending the game.");
    }
  };

  const incrementValue = () => {
    setModalValue(
      modalType === "currentOuts" && modalValue >= 3 ? 0 : modalValue + 1
    );
  };

  const decrementValue = () => {
    if (modalValue > 0) {
      setModalValue(
        modalType === "currentInning" && modalValue <= 1 ? 1 : modalValue - 1
      );
    }
  };

  const handleValueChange = (value) => {
    const numericValue = Number(value);
    if (modalType === "currentOuts" && numericValue > 3) {
      setModalValue(3);
    } else {
      setModalValue(numericValue);
    }
  };

  const handleScoreInning = async (inning, inningHalf) => {
    try {
      const updatedGame = { ...game };
      updatedGame.currentInning = inning || 1;
      updatedGame.inningHalf = inningHalf;
      await axios.put(`${BASE_URL}/api/game/${gameId}/update`, updatedGame);
      setGame(updatedGame);
      closeModal();
    } catch (error) {
      console.error("Error saving changes:", error);
      showAlert("Error", "There was an error saving the changes.");
    }
    navigation.navigate("ScoreNextInning", { gameId });
  };

  const handleAddInning = async () => {
    try {
      const updatedGame = { ...game };
      updatedGame.maxInnings = game?.maxInnings + 1;
      await axios.put(`${BASE_URL}/api/game/${gameId}/update`, updatedGame);
      setGame(updatedGame);
      closeModal();
    } catch (error) {
      console.error("Error adding inning:", error);
      showAlert("Error", "There was an error adding the inning.");
    }
    navigation.navigate("ScoreNextInning", { gameId });
  };

  if (!game) {
    return (
      <View style={styles.container}>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <InfoBox
        game={game}
        openModal={openModal}
        openHalfModal={openHalfModal}
      />
      <ScoreBoard
        game={game}
        handleScoreInning={handleScoreInning}
        handleAddInning={handleAddInning}
      />

      <BoxScoreModal
        isModalVisible={isModalVisible}
        closeModal={closeModal}
        modalType={modalType}
        modalValue={modalValue}
        incrementValue={incrementValue}
        decrementValue={decrementValue}
        handleValueChange={handleValueChange}
        saveChanges={saveChanges}
      />

      <IsHalfModal
        isHalfModalVisible={isHalfModalVisible}
        closeHalfModal={closeHalfModal}
        saveHalfChanges={saveHalfChanges}
        halfValue={halfValue}
        setHalfValue={setHalfValue}
      />

      <EndGameModal
        isEndGameModalVisible={isEndGameModalVisible}
        closeEndGameModal={closeEndGameModal}
        game={game}
        endGame={endGame}
      />

      <View style={styles.buttonContainer}>
        <View style={styles.button}>
          <Button
            title="Score Next Inning"
            onPress={() => navigation.navigate("ScoreNextInning", { gameId })}
          />
        </View>
        <View style={styles.button}>
          <Button title="End Game" onPress={openEndGameModal} color={"red"} />
        </View>
      </View>
    </View>
  );
};

export default BoxScore;
