import React, { useState, useEffect } from "react";
import { View, Text, TextInput, Button, StyleSheet, Alert, TouchableOpacity } from "react-native";
import axios from "axios";
import { useRoute, useNavigation } from "@react-navigation/native";
import { BASE_URL } from "@env";
import { Picker } from "@react-native-picker/picker";
import showAlert from "../utils/showAlert";
import styles from "./styles";

const AtBatEditScreen = () => {
  const route = useRoute();
  const navigation = useNavigation();
  const { atBatId } = route.params;
  const [atBat, setAtBat] = useState(null);
  const [result, setResult] = useState("");
  const [location, setLocation] = useState("");
  const [outcome, setOutcome] = useState("");
  const [RBIs, setRBIs] = useState(0);
  const [scored, setScored] = useState(false);

  useEffect(() => {
    fetchAtBatDetails();
  }, []);

  const fetchAtBatDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/atBat/${atBatId}`);
      const atBatData = response.data;
      setAtBat(atBatData);
      setResult(atBatData.result);
      setLocation(atBatData.location);
      setOutcome(atBatData.outcome);
      setRBIs(atBatData.RBIs);
      setScored(atBatData.scored);
    } catch (error) {
      console.error("Error fetching at-bat details:", error);
    }
  };

  const handleSaveAtBat = async () => {
    await saveAtBatDetails();
  };

  const saveAtBatDetails = async () => {
    try {
      if (RBIs < 0 || RBIs > 4) {
        showAlert("Invalid RBIs", "RBIs must be between 0 and 4.");
        return;
      }

      const updatedAtBat = {
        result,
        location,
        outcome,
        RBIs,
        scored: outcome === "Score",
      };

      await axios.put(`${BASE_URL}/api/atBat/${atBatId}`, updatedAtBat);

      navigation.goBack();
    } catch (error) {
      console.error("Error saving at-bat details:", error);
      showAlert("Error", "There was an error saving the at-bat details.");
    }
  };

  const handleArchiveAtBat = async () => {
    showAlert(
      "Confirm Archive",
      "Are you sure you want to archive this at-bat?",
      [
        {
          text: "Cancel",
          style: "cancel",
        },
        {
          text: "Archive",
          style: "destructive",
          onPress: async () => {
            try {
              await axios.delete(`${BASE_URL}/api/atBat/${atBatId}`);
              navigation.goBack();
            } catch (error) {
              console.error("Error archiving at-bat:", error);
              showAlert("Error", "There was an error archiving the at-bat.");
            }
          },
        },
      ],
      { cancelable: false }
    );
  };

  if (!atBat) {
    return (
      <View style={styles.container}>
        <Text>Loading...</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => navigation.navigate("EditPlayer", { playerId: atBat.player._id })}
      >
        <Text style={styles.h4}>
          Player: {atBat.player.firstName} {atBat.player.lastName}
        </Text>
      </TouchableOpacity>
      <Text style={styles.h6}>
        Inning: {atBat.inning} {atBat.inningHalf}
        {"\n"}
      </Text>

      <Text style={styles.label}>Result:</Text>
      <View style={styles.pickerContainer}>
        <Picker
          selectedValue={result}
          onValueChange={(itemValue) => setResult(itemValue)}
        >
          <Picker.Item label="Single" value="Single" />
          <Picker.Item label="Double" value="Double" />
          <Picker.Item label="Triple" value="Triple" />
          <Picker.Item label="Home Run" value="Home Run" />
          <Picker.Item label="Ground Out" value="Ground Out" />
          <Picker.Item label="Pop Out" value="Pop Out" />
          <Picker.Item label="Line Out" value="Line Out" />
          <Picker.Item label="Fly Out" value="Fly Out" />
          <Picker.Item label="Strike Out" value="Strike Out" />
          <Picker.Item label="Sacrifice Fly" value="Sacrifice Fly" />
          <Picker.Item label="Fielder's Choice" value="Fielder's Choice" />
        </Picker>
      </View>
      <Text style={styles.label}>Location:</Text>
      <View style={styles.pickerContainer}>
        <Picker
          selectedValue={location}
          onValueChange={(itemValue) => setLocation(itemValue)}
        >
          <Picker.Item label="P" value="P" />
          <Picker.Item label="C" value="C" />
          <Picker.Item label="1B" value="1B" />
          <Picker.Item label="2B" value="2B" />
          <Picker.Item label="3B" value="3B" />
          <Picker.Item label="SS" value="SS" />
          <Picker.Item label="LF" value="LF" />
          <Picker.Item label="CF" value="CF" />
          <Picker.Item label="RF" value="RF" />
          <Picker.Item label="LR" value="LR" />
          <Picker.Item label="RR" value="RR" />
          <Picker.Item label="NA" value="NA" />
        </Picker>
      </View>
      <Text style={styles.label}>Outcome:</Text>
      <View style={styles.pickerContainer}>
        <Picker
          selectedValue={outcome}
          onValueChange={(itemValue) => {
            setOutcome(itemValue);
            if (itemValue === "Score") {
              setScored(true);
            } else {
              setScored(false);
            }
          }}
        >
          <Picker.Item label="1B" value="1B" />
          <Picker.Item label="2B" value="2B" />
          <Picker.Item label="3B" value="3B" />
          <Picker.Item label="Score" value="Score" />
          <Picker.Item label="Out" value="Out" />
        </Picker>
      </View>
      <Text style={styles.label}>RBIs:</Text>
      <TextInput
        style={styles.input}
        keyboardType="numeric"
        value={String(RBIs)}
        onChangeText={(value) => setRBIs(Number(value))}
      />
      <View style={styles.buttonRow}>
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <Button title="Save" onPress={handleSaveAtBat} color="green" />
          </View>
        </View>
        <View style={styles.buttonContainer}>
          <View style={styles.button}>
            <Button
              title="Archive At-Bat"
              color="red"
              onPress={handleArchiveAtBat}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default AtBatEditScreen;
